import React from 'react';
import Header from '../Header/Header';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import './HelpComponent.css';
import alterarEmail from '../../Media/Help/alterarEmail.gif';
import receberBoleto from '../../Media/Help/receberBoleto.gif';
import criarPost from '../../Media/Help/criarPost.gif';
import criarChamado from '../../Media/Help/criarChamado.gif';

function HelpComponent({ user }) {
  if (user.admin === true) {
    return (
      <div className="HelpPage">
        <SidebarComponent user={user} />
        <div className="HelpMain">
          <Header headerData={{ user }} />

          <div className="helpContent">

            <div className="standardHomeDiv">
              <h1 className="headingText">Como criar um post no blog</h1>
              <img className="helpImage" alt="Gif de como criar um post no blog" src={criarPost} />
              <p style={{ marginTop: '24px' }} className="standardText">Para criar um novo post no blog, basta clicar na parte de Carmel Blog, preencher campos corretamente, escolher uma imagem para o post e clicar em Publicar</p>
            </div>

            <div className="standardHomeDiv">
              <h1 className="headingText">Como abrir chamado para criar cliente dentro do sistema do app</h1>
              <img className="helpImage" alt="Gif de como criar chamado para registro de novos clientes" src={criarChamado} />
              <p style={{ marginTop: '24px' }} className="standardText">Para criar um chamado de registro de novo cliente, basta clicar em Suporte Kaizen, clicar em cadastrar novo cliente e preencher os campos corretamente, colocando no campo de Mais informações o nome completo do usuario, seu cpf, seu email e seu número de identificação no sienge</p>
            </div>

          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="HelpPage">
      <SidebarComponent user={user} />
      <div className="HelpMain">
        <Header headerData={{ user }} />

        <div className="helpContent">
          <div className="standardHomeDiv">
            <h1 className="headingText">Como alterar seu email no sistema</h1>
            <img className="helpImage" alt="Gif de como alterar seu email" src={alterarEmail} />
            <p style={{ marginTop: '24px' }} className="standardText">Para alterar seu email no sistema, basta clicar em Editar Conta, depois no local onde informa seu email atual, clique em editar, preencha com um email válido e clique em Salvar Alterações</p>
          </div>
          <div className="standardHomeDiv">
            <h1 className="headingText">Como receber no email boletos de pagamento e contas a pagar</h1>
            <img className="helpImage" alt="Gif de como receber boletos e extrato por email" src={receberBoleto} />
            <p style={{ marginTop: '24px' }} className="standardText">Para receber seu boleto e contas a pagar no email, basta clicar em Pagamentos, e para o boleto clique em Enviar boleto por email, para as contas a pagar desça um pouco a página e na parte de resumo clique no botão "Enviar Conta por Email"</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpComponent;
