import React from 'react';
import { Redirect } from 'react-router';
import CriarPostComponent from '../components/Blog/CriarPost/CriarPostComponent';
import { fetchDataFromLocalStorage } from '../context/DatabaseConnection';

function CriarPost() {
  const { user } = fetchDataFromLocalStorage();

  if (user.admin !== true) {
    return <Redirect to="/home" />;
  }

  return (
    <CriarPostComponent />
  );
}

export default CriarPost;
