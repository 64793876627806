/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import AppContext from './AppContext';

function Provider({ children }) {
  const [userData, changeUserData] = React.useState(false);
  const [token, changeToken] = React.useState(false);
  const [notifications, changeNotifications] = React.useState(false);
  const contextValue = {
    notifications,
    changeNotifications,
    userData,
    changeUserData,
    token,
    changeToken,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
}

export default Provider;
