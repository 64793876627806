/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
// import history from '../../../context/history';
import './EditPostComponent.css';
import postCreator from '../../../Media/Blog/postCreator.png';
import postTitleImg from '../../../Media/Blog/postTitle.png';
import readTime from '../../../Media/Blog/readTime.png';
import databaseUrl, { fetchDataFromLocalStorage } from '../../../context/DatabaseConnection';
import loading from '../../../Media/Blog/loading.gif';

function EditPostComponent({ postData }) {
  const [postImage, loadPostImage] = React.useState('');
  const [postTitle, changePostTitle] = React.useState(postData.title);
  const [postBody, changePostBody] = React.useState(postData.content);
  const [postAuthor, changePostAuthor] = React.useState(postData.postAuthor);
  const [postReadTime, changePostReadTime] = React.useState(postData.readTime.toString());

  React.useEffect(() => {
    async function fetchData() {
      const data = await fetch(`${databaseUrl}/images/${postData.image}`);
      return data;
    }
    if (postData !== undefined) {
      fetchData().then((res) => {
        loadPostImage(res.url);
      });
    }
  }, [postData]);

  function handleGoBack() {
    window.location.reload();
  }

  function checkData() {
    if (postTitle !== '' && postBody !== '' && postAuthor !== '' && postReadTime !== '') {
      return false;
    }
    return true;
  }

  async function handleChange(element) {
    switch (element.name) {
      case 'title':
        changePostTitle(element.value);
        break;
      case 'body':
        changePostBody(element.value);
        break;
      case 'author':
        changePostAuthor(element.value);
        break;
      // case 'date':
      //   changePostDate(element.value);
      //   break;
      case 'readTime':
        if (isNaN(element.value)) {
          break;
        }
        changePostReadTime(element.value);
        break;
      default:
        break;
    }
  }

  async function handleClick() {
    const disabled = checkData();
    if (disabled === true) {
      return window.alert('Algum campo está vazio, preencha todos para editar o post com sucesso.');
    }
    const localStorageToken = fetchDataFromLocalStorage().token;

    const response = await fetch(`${databaseUrl}/post/${postData.id}`, {
      method: 'PUT',
      headers: {
        authorization: localStorageToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title: postTitle,
        content: postBody,
        readTime: postReadTime,
        postAuthor,
      }),
    });
  }

  async function handleDeleteClick() {
    const localStorageToken = fetchDataFromLocalStorage().token;

    const response = await fetch(`${databaseUrl}/post/${postData.id}`, {
      method: 'DELETE',
      headers: {
        authorization: localStorageToken,
        'Content-Type': 'application/json',
      },
    });

    window.location.reload();
  }

  return (
    <div className="EditPostComponent standardHomeDiv">
      <div className="formHeader">
        <h1 className="headingText">Editar Publicação</h1>
        <p onClick={handleGoBack} className="standardText goBackText">Voltar</p>
      </div>

      <div className="editPostBody">
        <div className="iconAndInput">
          <img src={postTitleImg} alt="" />
          <input className="inputWithBottomBorder" value={postTitle} name="title" onChange={(e) => handleChange(e.target)} />
        </div>
        <div className="editReadTimeAndAuthor">

          <div className="iconAndInput">
            <img src={readTime} alt="" />
            <input className="inputWithBottomBorder" value={postReadTime} name="readTime" onChange={(e) => handleChange(e.target)} />
          </div>

          <div className="iconAndInput">
            <img src={postCreator} alt="" />
            <input className="inputWithBottomBorder" value={postAuthor} name="author" onChange={(e) => handleChange(e.target)} />
          </div>

        </div>

        <img src={postImage} alt="" />

        <textarea className="standardInput" value={postBody} name="body" onChange={(e) => handleChange(e.target)} />

        <button onClick={handleClick} type="button" className="genericBtn">
          Salvar Modificações
        </button>

        <button style={{ marginTop: '40px' }} onClick={handleDeleteClick} type="button" className="genericBtn">
          Deletar post
        </button>

      </div>
    </div>
  );
}

export default EditPostComponent;
