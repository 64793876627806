/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import '../components/Login/LoginComponent.css';
import carmelLogo from '../Media/Login/carmelLogo.webp';
import setaPreta from '../Media/Login/setaPreta.webp';
import carmelMiniLogo from '../Media/Login/carmelMiniLogo.webp';
import CARMEL from '../Media/Login/CARMEL.webp';
import databaseUrl from '../context/DatabaseConnection';
import history from '../context/history';

function CriarSenha() {
  const [senha, changeSenha] = React.useState('');
  const [email, changeEmail] = React.useState('');
  const [cpf, changeCpf] = React.useState('');
  const [confirmarSenha, changeConfirmarSenha] = React.useState('');

  function handleStartClick() {
    document.querySelector('.LoginForm').style.display = 'flex';
    document.querySelector('.LogoAndButtonComponent').style.width = '0';
    document.querySelector('.LogoAndButtonComponent').style.padding = '0';
  }

  function validateEmail(mail) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
  }

  function enableButton() {
    const emailValid = validateEmail(email);
    if (emailValid && senha !== '' && confirmarSenha === senha && cpf !== '') {
      return false;
    }
    return true;
  }

  function handleChange({ target }) {
    if (target.name === 'senha') {
      changeSenha(target.value);
    }
    if (target.name === 'email') {
      changeEmail(target.value);
    }
    if (target.name === 'confirmarSenha') {
      changeConfirmarSenha(target.value);
    }
    if (target.name === 'cpf') {
      if (isNaN(target.value) === false) {
        changeCpf(target.value);
      }
    }
    enableButton();
  }

  async function handleClick() {
    const enabledBtn = enableButton();
    if (enabledBtn === true) {
      return window.alert('Por Favor, preencha todos os campos e verifique se as senhas são iguais antes de continuar');
    }
    const response = await fetch(`${databaseUrl}/loginNoPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cpf,
        email,
        password: senha,
      }),
    });
    const res = await response.json();
    if (res.message !== undefined) {
      return window.alert('Usuário com essa combinação de cpf e email não encontrados!');
    }
    const response2 = await fetch(`${databaseUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password: senha,
      }),
    });
    const res2 = await response2.json();
    localStorage.setItem('User', JSON.stringify(res2.userData));
    localStorage.setItem('Token', JSON.stringify(res2.token));
    return history.push('/home');
  }

  return (
    <div className="LoginComponent">

      <div className="LogoAndButtonComponent">
        <img src={carmelLogo} alt="Logo Da Carmel" />
        <button onClick={() => handleStartClick()} type="button" id="startBtnMobile">
          Começar
          <img src={setaPreta} alt="" />
        </button>
      </div>

      <div className="LoginForm">

        <div className="LogoAndLabel">
          <img src={carmelMiniLogo} alt="Carmel Logo" />
          <img src={CARMEL} alt="CARMEL" />
        </div>

        <br />

        <div className="credentialsInput">

          <h2>Olá!</h2>
          <h3>Crie sua senha e tenha acesso ao app</h3>
          <br />

          <div className="actualInput">
            <div className="inputLabel">
              Email
              <br />
              <input value={email} onChange={(e) => handleChange(e)} name="email" type="email" />
            </div>
            <br />

            <div className="inputLabel">
              CPF ou CNPJ (Apenas Números)
              <br />
              <input value={cpf} onChange={(e) => handleChange(e)} name="cpf" type="input" />
            </div>
            <br />

            <div className="inputLabel">
              Senha
              <br />
              <input value={senha} onChange={(e) => handleChange(e)} name="senha" type="password" />
            </div>
            <br />

            <div className="inputLabel">
              Confirmar Senha
              <br />
              <input value={confirmarSenha} onChange={(e) => handleChange(e)} name="confirmarSenha" type="password" />
            </div>
          </div>

        </div>
        <button onClick={handleClick} type="button" className="LoginBtn">
          Criar Senha
        </button>
      </div>

    </div>
  );
}

export default CriarSenha;
