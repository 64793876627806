import React from 'react';
import AtendimentoComponent from '../components/Atendimento/AtendimentoComponent';

function AtendimentoPage() {
  return (
    <AtendimentoComponent />
  );
}

export default AtendimentoPage;
