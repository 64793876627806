import React from 'react';
import EditarContaComponent from '../components/EditarConta/EditarContaComponent';

function EditarContaPage() {
  return (
    <EditarContaComponent />
  );
}

export default EditarContaPage;
