import React from 'react';
import CriarUsuarioComponent from '../components/CriarUsuario/CriarUsuarioComponent';
import { fetchDataFromLocalStorage } from '../context/DatabaseConnection';
import history from '../context/history';

function CriarUsuario() {
  const { user, token } = fetchDataFromLocalStorage();

  if (user.admin !== true) {
    return history.push('/home');
  }

  return (
    <div>
      <CriarUsuarioComponent user={user} token={token} />
    </div>
  );
}

export default CriarUsuario;
