/* eslint-disable max-len */
import React from 'react';
import { fetchDataFromLocalStorage } from '../../context/DatabaseConnection';
import Header from '../Header/Header';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import './AtendimentoComponent.css';
import orangeWhatsapp from '../../Media/Atendimento/orangeWhatsapp.png';
import orangeMail from '../../Media/Atendimento/orangeMail.png';
import blackWhatsapp from '../../Media/Atendimento/blackWhatsapp.png';
import blackMail from '../../Media/Atendimento/blackMail.png';

function AtendimentoComponent() {
  const { user } = fetchDataFromLocalStorage();
  const [whatsapp, changeWhatsapp] = React.useState(orangeWhatsapp);
  const [mail, changeMail] = React.useState(orangeMail);
  const [whatsapp2, changeWhatsapp2] = React.useState(orangeWhatsapp);
  const [mail2, changeMail2] = React.useState(orangeMail);

  function handleCopyClick(clickedElement) {
    switch (clickedElement) {
      case 'financeiro':
        navigator.clipboard.writeText('cobranca@bertoliniempreendimentos.com.br');
        window.alert('Email do financeiro copiado com sucesso!');
        break;
      case 'posVenda':
        navigator.clipboard.writeText('comercial@bertoliniempreendimentos.com.b');
        window.alert('Email do Pós Vendas copiado com sucesso!');
        break;
      default:
        break;
    }
  }

  return (
    <div className="AtendimentoPage">
      <SidebarComponent user={user} />
      <div className="atendimentoMain">

        <Header headerData={{ user }} />

        <p className="standardText">Estamos à disposição para ajudar</p>

        <div className="atendimentoContent">

          <div className="atnFirstDiv atnDiv">
            <div className="standardHomeDiv">
              <h3 className="biggerText">Atendimento Financeiro</h3>

              <button onClick={() => window.open('https://api.whatsapp.com/send?phone=5592986367552&text=Ol%C3%A1,%20estou%20entrando%20em%20contato%20para%20suporte%20financeiro%20atrav%C3%A9s%20do%20Carmel%20App!')} onMouseEnter={() => changeWhatsapp(blackWhatsapp)} onMouseLeave={() => changeWhatsapp(orangeWhatsapp)} type="button" className="atnBtn btnWithImage genericBtn">
                <img src={whatsapp} alt="" />
                <p>Whatsapp</p>
              </button>

              <button onClick={() => handleCopyClick('financeiro')} onMouseEnter={() => changeMail(blackMail)} onMouseLeave={() => changeMail(orangeMail)} type="button" className="atnBtn btnWithImage genericBtn">
                <img src={mail} alt="" />
                <p>Copiar Email</p>
              </button>
            </div>

            <div className="standardHomeDiv">
              <h3 className="biggerText">Atendimento Pós vendas</h3>

              <button onClick={() => window.open('https://api.whatsapp.com/send?phone=5592985595973&text=Ol%C3%A1,%20estou%20entrando%20em%20contato%20para%20suporte%20de%20p%C3%B3s%20vendas%20atrav%C3%A9s%20do%20Carmel%20App')} onMouseEnter={() => changeWhatsapp2(blackWhatsapp)} onMouseLeave={() => changeWhatsapp2(orangeWhatsapp)} type="button" className="atnBtn btnWithImage genericBtn">
                <img src={whatsapp2} alt="" />
                <p>Whatsapp</p>
              </button>

              <button onClick={() => handleCopyClick('posVenda')} onMouseEnter={() => changeMail2(blackMail)} onMouseLeave={() => changeMail2(orangeMail)} type="button" className="atnBtn btnWithImage genericBtn">
                <img src={mail2} alt="" />
                <p>Copiar Email</p>
              </button>
            </div>
          </div>

          <div className="atnSecondDiv">
            <div className="standardHomeDiv">
              <h3 className="biggerText">Dúvidas Frequentes</h3>
              <ul>
                <li>
                  <h4 className="standardText">Antecipar pagamento de parcelas</h4>
                  <p>Caso esteja no modelo IGP-M, ocorrerá a descapitalização dos juros cobrados na proposta de venda, ou seja, serão abatidos</p>
                </li>

                <li>
                  <h4 className="standardText">Alteração da amortização</h4>
                  <p>Caso você queira alterar o índice de amortização, entre em contato com o financeiro da Carmel para simulação do novo plano e criação de novas parcelas</p>
                </li>
                <li>
                  <h4 className="standardText">Atraso do pagamento do boleto</h4>
                  <p>Se o atraso foi maior que 30 dias, o financeiro da Carmel deverá ser contatado para emissão de um novo boleto com valores atualizados</p>
                </li>
              </ul>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default AtendimentoComponent;
