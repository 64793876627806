/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './Header.css';
import times from '../../Media/Header/times.svg';
import databaseUrl from '../../context/DatabaseConnection';

function NPSComponent({ npsState, setNpsState, token }) {
  // eslint-disable-next-line no-unused-vars
  const [npsScoreRating, setNpsScoreRating] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [npsRatingStyle, setRatingStyle] = React.useState({ color: '#444444' });
  const [numeralsCollection, setNumeralsColletion] = React.useState();
  const [chosenNumeral, setChosenNumeral] = React.useState(undefined);
  const [feedbackText, setFeedbackText] = React.useState('');

  const numeralsContainer = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  React.useEffect(() => {
    const collection = Array.from(document.getElementsByClassName('npsNumeral'));

    setNumeralsColletion(collection);
  }, [npsState]);

  function setStyle(selectedNumeral) {
    if (chosenNumeral !== undefined) {
      return;
    }
    let idToSet = 'redNumeral';
    if (selectedNumeral <= 4) {
      setNpsScoreRating('O App não me satisfez :(');
      setRatingStyle({ color: '#D00101' });
    } else if (selectedNumeral > 4 && selectedNumeral <= 6) {
      setNpsScoreRating('Ainda precisa melhorar!');
      setRatingStyle({ color: '#444444' });
      idToSet = 'greyNumeral';
    } else if (selectedNumeral > 6) {
      setNpsScoreRating('O app é muito útil :)');
      setRatingStyle({ color: '#009C06' });
      idToSet = 'greenNumeral';
    }
    for (let index = 0; index < selectedNumeral; index += 1) {
      numeralsCollection[index].id = idToSet;
    }
  }

  function resetStyle() {
    if (chosenNumeral !== undefined) {
      return;
    }
    setNpsScoreRating('');
    numeralsCollection.forEach((numeral) => { numeral.id = ''; });
  }

  function handleNumeralClick(selectedNumeral) {
    setChosenNumeral(selectedNumeral);
    setRatingStyle({ color: '#444444' });
    numeralsCollection.forEach((numeral) => { numeral.id = ''; numeral.className = 'npsNumeral'; });
    for (let index = 0; index < selectedNumeral; index += 1) {
      numeralsCollection[index].className = 'npsNumeral chosenNumerals';
    }
  }

  async function submitNPS() {
    const feedbackBody = { rating: chosenNumeral };
    if (feedbackText !== '') {
      feedbackBody.feedbackText = feedbackText;
    }
    await fetch(`${databaseUrl}/feedbacks`, {
      method: 'POST',
      headers: {
        authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedbackBody),
    });
    localStorage.setItem('NPSstate', 'Completa');
    window.location.reload();
  }

  return (
    <div className="npsDiv" style={npsState ? { display: 'flex' } : { display: 'none' }}>
      <img id="closeNps" alt="Fechar Pesquisa" src={times} width="20px" onClick={() => setNpsState(false)} />
      <div className="npsMain">

        <h2>Quanto você recomendaria o Carmel App?</h2>

        <div className="npsNumeralsContainer">
          {numeralsContainer.map((numeral) => {
            return (
              <div key={numeral} onClick={() => handleNumeralClick(numeral)} onMouseEnter={() => setStyle(numeral)} onMouseLeave={() => resetStyle()} className="npsNumeral">
                {numeral}
              </div>
            );
          })}
        </div>

        <p style={npsRatingStyle} id="npsScoreRating">{npsScoreRating}</p>

        {
          chosenNumeral === undefined ? ''
            : (
              <div className="npsForm">
                <h2>Sugestões são bem vindas, onde podemos melhorar?</h2>

                <textarea placeholder="Digite o texto aqui" value={feedbackText} onChange={(e) => setFeedbackText(e.target.value)} />

                <div className="npsFinisher">
                  <button onClick={submitNPS} type="button">Enviar</button>
                </div>
              </div>
            )
        }

      </div>
    </div>
  );
}

export default NPSComponent;
