import React from 'react';
import SidebarComponent from '../components/SidebarComponent/SidebarComponent';
import Header from '../components/Header/Header';
import { fetchDataFromLocalStorage } from '../context/DatabaseConnection';
import '../components/CriarUsuario/CriarUsuarioComponent.css';

function SuportePage() {
  const { user } = fetchDataFromLocalStorage();
  return (
    <div className="CriarUsuarioPage">
      <SidebarComponent user={user} />

      <div className="CriarUsuarioComponent">

        <Header headerData={{ user }} />

        <div className="criarUsuarioMain">
          <iframe className="suportIframe" title="criarClienteNoApp" src="https://portal.pipefy.com/271b5d91-2191-4b4a-b96b-c9d44d7b337f" />
        </div>

      </div>
    </div>
  );
}

export default SuportePage;
