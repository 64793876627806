/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PostComponent from './PostComponent';
import databaseUrl, { fetchDataFromLocalStorage } from '../../context/DatabaseConnection';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import './BlogComponent.css';
import CriarPostComponent from './CriarPost/CriarPostComponent';
import Header from '../Header/Header';
import smallerPostDate from '../../Media/Blog/smallerPostDate.png';
import smallerReadTime from '../../Media/Blog/smallerReadTime.png';
import EditPostComponent from './EditarPost/EditPostComponent';

function BlogComponent() {
  const [postData, setPostData] = React.useState(undefined);
  const [allPosts, setAllPosts] = React.useState([]);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [rascunho, setRascunho] = React.useState(undefined);
  const [reRender, setReRender] = React.useState(false);

  const { user } = fetchDataFromLocalStorage();

  let mainBlogDiv = (
    <PostComponent postData={postData} />
  );

  function handleDraftClick(draft) {
    setRascunho(draft);
  }

  function appendPost(receivedPost) {
    if (user.admin === true) {
      setFirstLoad(false);
      setReRender(receivedPost);
      return;
    }
    const newAllPosts = allPosts.filter((post) => post.id !== receivedPost.id);
    newAllPosts.push(postData);
    setAllPosts(newAllPosts);
    setPostData(receivedPost);
  }

  React.useEffect(() => {
    async function fetchData() {
      const data = await fetch(`${databaseUrl}/posts`);
      const response = await data.json();
      return response;
    }

    if (postData === undefined) {
      fetchData().then((res) => {
        setPostData(res[res.length - 1]);
        if (user.admin !== true) {
          res.pop();
        }
        setAllPosts(res);
      });
    }
  }, [postData, rascunho]);

  if (user.admin === true && firstLoad === true) {
    const drafts = JSON.parse(localStorage.getItem('blogPostsDrafts'));
    mainBlogDiv = (
      <div>
        <div className="standardHomeDiv">
          <CriarPostComponent rascunho={rascunho} />
        </div>
        <div className="draftsDiv">
          <h2 style={{ marginLeft: '28px' }} className="headingText">Rascunhos</h2>
          {
          drafts !== null && drafts !== undefined ? drafts.map((draft) => (
            <div role="presentation" onClick={() => handleDraftClick(draft)} className="draftTitle" key={draft.title}>
              <h3 className="standardText">
                {draft.title}
              </h3>
              <h3 className="standardText">{'>'}</h3>
            </div>
          )) : <p style={{ marginLeft: '28px' }} className="standardText">Salve um rascunho para ele aparecer aqui!</p>
          }
        </div>
      </div>
    );
  }

  if (reRender !== false) {
    return (
      <div className="BlogPage">

        <SidebarComponent user={user} />

        <div className="BlogComponent">
          <Header headerData={{ user }} />

          <div className="blogContent">

            <div className="mainBlogDiv">
              <EditPostComponent postData={reRender} />
            </div>

            <div className="secondBlogDiv">
              <div className="allPostsDiv standardHomeDiv">
                <h2 className="headingText">Últimas Publicações</h2>
                {allPosts.map((post) => {
                  const dateArray = post.published.split('-');
                  const postDate = (`${(dateArray[2]).slice(0, 2)}.${dateArray[1]}.${dateArray[0]}`);

                  return (
                    <div key={post.id} className="smallerPostDiv">
                      <h3 className="postTitleSidebar">{post.title}</h3>
                      <div className="smallerPostHeader">
                        <div className="postHeaderAndIcon">
                          <img src={smallerPostDate} alt="calendar-icon" />
                          <p>{postDate}</p>
                        </div>
                        <div className="postHeaderAndIcon">
                          <img src={smallerReadTime} alt="readtime-icon" />
                          <p>{`${post.readTime} min de leitura`}</p>
                        </div>
                      </div>
                      <p className="evenSmallerText">{post.content.split(/\r?\n|\r|\n/g)[0]}</p>
                      <p onClick={() => appendPost(post)} className="seePostText">
                        {user.admin === true ? 'Editar >' : 'Ver Mais >'}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }

  return (
    <div className="BlogPage">

      <SidebarComponent user={user} />

      <div className="BlogComponent">
        <Header headerData={{ user }} />

        <div className="blogContent">

          <div className="mainBlogDiv">
            {mainBlogDiv}
          </div>

          <div className="secondBlogDiv">
            <div className="allPostsDiv standardHomeDiv">
              <h2 className="headingText">Últimas Publicações</h2>
              {allPosts.map((post) => {
                const dateArray = post.published.split('-');
                const postDate = (`${(dateArray[2]).slice(0, 2)}.${dateArray[1]}.${dateArray[0]}`);

                return (
                  <div key={post.id} className="smallerPostDiv">
                    <h3 className="smallerText">{post.title}</h3>
                    <div className="smallerPostHeader">
                      <div className="postHeaderAndIcon">
                        <img src={smallerPostDate} alt="calendar-icon" />
                        <p>{postDate}</p>
                      </div>
                      <div className="postHeaderAndIcon">
                        <img src={smallerReadTime} alt="readtime-icon" />
                        <p>{`${post.readTime} min de leitura`}</p>
                      </div>
                    </div>
                    <p className="evenSmallerText">{post.content.split(/\r?\n|\r|\n/g)[0]}</p>
                    <p onClick={() => appendPost(post)} className="seePostText">
                      {user.admin === true ? 'Editar >' : 'Ver Mais >'}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default BlogComponent;
