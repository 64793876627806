/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import './HomeComponent.css';
import history from '../../context/history';
import Header from '../Header/Header';
import databaseUrl, { fetchDataFromLocalStorage } from '../../context/DatabaseConnection';

function HomeComponent({ user, clients }) {
  const [galleryImage, setGalleryImage] = React.useState('');
  let homeVariable = 'Você está em casa.';

  const { token } = fetchDataFromLocalStorage();

  React.useEffect(() => {
    async function fetchImage() {
      const response = await fetch(`${databaseUrl}/gallery`, {
        method: 'GET',
        headers: { authorization: token },
      });
      const res = await response.json();
      if (res.message !== undefined) {
        return { url: '' };
      }
      const { filename } = res[0];
      if (filename) {
        const image = await fetch(`${databaseUrl}/gallery/${filename}`);
        return image;
      }
      return { url: '' };
    }
    fetchImage().then((aaa) => {
      setGalleryImage(aaa.url);
    });
  }, []);

  // const dayForNextPayment = 20;
  let clientsNumber = 3;
  if (clients !== false) {
    clientsNumber = clients;
  }

  const payment = <p className="standardText">Veja seus últimos pagamentos e gere novos boletos</p>;

  function handleClick(clickedElement) {
    switch (clickedElement) {
      case 'blog':
        history.push('/blog');
        break;
      case 'atendimento':
        history.push('/atendimento');
        break;
      case 'adicionarCliente':
        history.push('/clientes');
        break;
      case 'galeria':
        history.push('/galeria');
        break;
      default:
        break;
    }
  }

  let homeFirstContent = (
    <div className="paymentDiv standardHomeDiv">
      <h3 className="headingText">Pagamentos</h3>
      {payment}
      <div className="twoButtonsDiv">
        {/* <button className="genericBtn" type="button">Gerar Boleto</button> */}
        <button className="genericBtn" onClick={() => history.push('pagamentos')} type="button">Ver Últimos Boletos</button>
      </div>
    </div>
  );

  let homeSecondContent = (
    <div className="galleryDiv standardHomeDiv">
      <h3 className="headingText">Acompanhe a construção</h3>
      <p className="standardText">Acesse a Galeria e veja imagens da obra</p>
      <div className="galleryBanner">
        <img src={galleryImage} alt="" />
      </div>
      <button onClick={() => handleClick('galeria')} className="genericBtn" type="button">Ver mais detalhes</button>
    </div>
  );

  let homeThirdContent = (
    <div />
  );

  let homeFourthContent = (
    <div className="standardHomeDiv">
      <h3 className="headingText">Fale conosco</h3>
      <p className="standardText">Estamos à disposição para ajudar você</p>
      <button type="button" onClick={() => handleClick('atendimento')} className="genericBtn">Atendimento 24 horas</button>
    </div>
  );

  let homeFifthContent = (
    <div className="standardHomeDiv">
      <h3 className="headingText">Carmel Blog</h3>
      <p className="standardText">Fique por dentro das novidades do primeiro Condominium Spa Resort de Manaus</p>
      <button type="button" onClick={() => handleClick('blog')} className="genericBtn">Ler última matéria</button>
    </div>
  );

  if (user.admin === true) {
    homeVariable = '';

    homeFirstContent = (
      <div className="clientsDiv standardHomeDiv">
        <h3 className="headingText">Clientes Carmel</h3>
        <p className="standardText">Total: <span className="greenText">{clientsNumber} clientes</span></p>
        <div className="twoButtonsDiv">
          <button className="genericBtn" onClick={() => handleClick('adicionarCliente')} type="button"><span className="plus">+</span> Adicionar</button>
        </div>
      </div>
    );

    homeSecondContent = (
      <div />
      // <div className="documentationDiv standardHomeDiv">
      //   <button className="genericBtn documentationBtn" type="button"><span className="plus">+</span> Documento de Identificação</button>
      //   <button className="genericBtn documentationBtn" type="button"><span className="plus">+</span> Comprovante de Residência</button>
      //   <button className="genericBtn documentationBtn" type="button"><span className="plus">+</span> Registro do Imóvel</button>
      //   <button className="genericBtn documentationBtn" type="button"><span className="plus">+</span> Escritura Pública</button>
      //   <button className="genericBtn documentationBtn" type="button"><span className="plus">+</span> Apólice de Seguros</button>
      //   <button className="genericBtn documentationBtn" type="button"><span className="plus">+</span> Outros</button>
      // </div>
    );

    homeThirdContent = (
      <div className="standardHomeDiv">
        <h3 className="headingText">Carmel Blog</h3>
        <div className="twoButtonsDiv">
          <button onClick={() => handleClick('blog')} className="genericBtn" type="button"><span className="plus">+</span> Nova Publicação</button>
          <button onClick={() => handleClick('blog')} className="genericBtn marginLeftBtn" type="button">Últimas Publicações</button>
        </div>
      </div>
    );

    homeFourthContent = (
      <div className="standardHomeDiv">
        <h3 className="headingText">Atendimento</h3>
        <button className="genericBtn" onClick={() => handleClick('atendimento')} type="button">Atendimento</button>
      </div>
    );

    homeFifthContent = (
      <div className="galleryDiv standardHomeDiv">
        <h3 className="headingText">Galeria</h3>
        <div className="twoButtonsDiv">
          <button onClick={() => handleClick('galeria')} className="genericBtn" type="button"><span className="plus">+</span> Adicionar Mídia</button>
        </div>
        <div className="galleryBanner">
          <img src={galleryImage} alt="" />
        </div>
      </div>
    );
  }

  return (
    <div className="HomePage">
      <SidebarComponent user={user} />
      <div className="HomeComponent">

        <Header headerData={{ user }} />

        <p id="homeText">{homeVariable}</p>

        <div className="homeContent">
          <div className="homeFirstDiv">
            {homeFirstContent}
            {homeSecondContent}
            {homeThirdContent}
          </div>

          <div className="homeSecondDiv">
            {homeFourthContent}
            {homeFifthContent}
          </div>
        </div>

      </div>
    </div>
  );
}

export default HomeComponent;
