/* eslint-disable import/no-mutable-exports */
let databaseUrl = 'https://carmel-web-app.herokuapp.com';

const development = true;

if (development === false) {
  databaseUrl = '';
}

export default databaseUrl;

const getMonths = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

function fetchDataFromLocalStorage() {
  const user = JSON.parse(localStorage.getItem('User'));
  const token = JSON.parse(localStorage.getItem('Token'));
  const NPSpopupState = localStorage.getItem('NPSstate');
  return { user, token, NPSpopupState };
}

export { development, fetchDataFromLocalStorage, getMonths };
