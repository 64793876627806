/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import ReactModal from 'react-modal';
import databaseUrl, { fetchDataFromLocalStorage } from '../../context/DatabaseConnection';
import Header from '../Header/Header';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import './GaleriaComponent.css';
import ImageComponent from './ImageComponent';
import fullscreen from '../../Media/Galeria/fullscreen.svg';
import excluir from '../../Media/Galeria/excluir.svg';
import voltarImagem from '../../Media/Galeria/voltarImagem.svg';
import proximaImagem from '../../Media/Galeria/proximaImagem.svg';
import ConstructionProgress from '../Home/ConstructionProgress/ConstructionProgress';

function GaleriaComponent() {
  const { user, token } = fetchDataFromLocalStorage();
  const [imageSelected, triggerImageSelected] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [galleryImages, setGalleryImages] = React.useState([]);
  const [imageIndexer, setImageIndexer] = React.useState(0);
  const [modalState, setModalState] = React.useState(false);
  const [editData, setEditData] = React.useState(false);
  const [constructionProgress, setConstructionProgress] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      const response = await fetch(`${databaseUrl}/gallery`, {
        method: 'GET',
        headers: { authorization: token },
      });
      const res = await response.json();
      return res;
    }
    async function fetchConstructionData() {
      const response = await fetch(`${databaseUrl}/progress`, {
        method: 'GET',
        headers: { authorization: token },
      });
      const res = await response.json();
      return res;
    }
    if (galleryImages.length === 0) {
      fetchData().then(async (res) => {
        if (res.length !== undefined) {
          setGalleryImages(res);
        }
      });
    }
    if (constructionProgress.length === 0) {
      fetchConstructionData().then(async (res) => {
        setConstructionProgress(res);
      });
    }
  }, [galleryImages]);

  async function handleClick() {
    const fileInput = document.getElementById('imageInput');
    const [chosenImage] = fileInput.files;
    if (chosenImage === undefined) {
      window.alert('Selecione uma imagem para enviar a Galeria');
      triggerImageSelected(false);
      return;
    }
    if (sending === true) {
      return;
    }
    setSending(true);
    const formData = new FormData();
    formData.append('file', chosenImage);
    await fetch(`${databaseUrl}/gallery`, {
      method: 'POST',
      headers: {
        authorization: token,
      },
      body: formData,
    });

    window.location.reload();
  }

  function handleSwitchClick(goBack) {
    if (goBack === true) {
      if (imageIndexer === 0) {
        setImageIndexer(galleryImages.length - 1);
        return;
      }
      setImageIndexer(imageIndexer - 1);
      return;
    }

    if (imageIndexer === galleryImages.length - 1) {
      setImageIndexer(0);
      return;
    }

    setImageIndexer(imageIndexer + 1);
    console.log(imageIndexer);
  }

  async function deleteImage() {
    const imageId = galleryImages[imageIndexer].id;
    console.log(imageId);

    await fetch(`${databaseUrl}/gallery/${imageId}`, {
      method: 'DELETE',
      headers: {
        authorization: token,
      },
    });

    window.location.reload();
  }

  async function saveData() {
    const inputs = Array.from(document.getElementsByClassName('standardInput'));
    inputs.every(async (input) => {
      const { id, value } = input;
      if (value !== '') {
        if (value.length > 2 && value !== '100') {
          window.alert('Insira um valor válido para salvar os dados');
          return false;
        }
        await fetch(`${databaseUrl}/progress/${id}`, {
          method: 'PUT',
          headers: { authorization: token, 'Content-Type': 'application/json' },
          body: JSON.stringify({
            percentageCompleted: value,
          }),
        });
      }
      console.log(id, value);
      return true;
    });
    window.location.reload();
  }

  return (
    <div className="GaleriaPage">
      <SidebarComponent user={user} />
      <ReactModal
        appElement={document.getElementById('root')}
        shouldCloseOnEsc
        isOpen={modalState}
      >
        <div className="modalDiv">
          <div className="modalHeader">
            <button onClick={() => handleSwitchClick(true)} className="switchImagesBtn" type="button">{'<'}</button>
            <button onClick={() => { setModalState(false); }} type="button" className="genericBtn">Sair da tela cheia</button>
            <button onClick={handleSwitchClick} className="switchImagesBtn" type="button">{'>'}</button>
          </div>
          <ImageComponent filename={galleryImages[imageIndexer]} modalId="modalId" />
        </div>
      </ReactModal>
      <div className="galeriaMain">

        <Header headerData={{ user }} />

        <h3>Detalhes da obra</h3>
        <div className="galeriaContent">

          <div style={{ marginRight: '48px' }} id="firstGalleryDiv" className="standardHomeDiv">
            <h3 className="headingText">Fotos</h3>

            { user.admin === true ? (
              <div className="twoButtonsDiv">
                <label style={imageSelected === false ? { display: 'flex' } : { display: 'none' }} onClick={() => { triggerImageSelected(true); }} className="fileInput" htmlFor="imageInput">
                  Adicionar Mídia
                  <input accept="image/png, image/jpeg, image/jpg, image/webp" name="imageInput" type="file" id="imageInput" />
                </label>

                <button style={imageSelected === true ? { display: 'flex' } : { display: 'none' }} onClick={handleClick} type="button" className="genericBtn">
                  {sending === false ? 'Enviar Mídia Selecionada' : 'Enviando Imagem'}
                </button>
              </div>
            ) : '' }

            {
              galleryImages.length === 0 ? <h1 className="headingText" style={{ marginTop: '24px' }}>Ainda não existem imagens na galeria</h1> : (
                <div>
                  <div className="imagesAndButtons">
                    <button onClick={() => handleSwitchClick(true)} className="switchImagesBtn" type="button"><img src={voltarImagem} alt="<" /></button>
                    <div className="mainArrayImage">
                      <ImageComponent filename={galleryImages[imageIndexer]} />
                      <div className="bottomImageDiv">
                        { user.admin === true ? <button onClick={deleteImage} className="imageOptionsBtn" type="button"><img src={excluir} alt="excluir imagem" /></button> : '' }
                        <button onClick={() => { setModalState(true); }} style={{ marginLeft: '16px' }} className="imageOptionsBtn" type="button"><img src={fullscreen} alt="tela cheia" /></button>
                      </div>
                    </div>
                    <button onClick={handleSwitchClick} className="switchImagesBtn" type="button"><img src={proximaImagem} alt=">" /></button>
                  </div>

                  <div className="restOfImages">
                    { galleryImages.map((image, currentIndexer) => {
                      if (currentIndexer === imageIndexer) {
                        return;
                      }
                      return (
                        <div style={{ cursor: 'pointer' }} onClick={() => { setImageIndexer(currentIndexer); }} id={currentIndexer} key={image.id} className="smallerImageContainer">
                          <ImageComponent smallerImage filename={image} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
            }

          </div>

          {/* <div className="standardHomeDiv">
            <h3 className="headingText">Números Atualizados</h3>
            {
              user.admin === true
                ? editData === false ? <button type="button" className="genericBtn" onClick={() => setEditData(true)}>Editar Dados</button> : <button type="button" className="genericBtn" onClick={saveData}>Salvar Dados</button>
                : ''
            }
            <div className="progressContainer">
              {
                editData === true ? <h2 style={{ marginTop: '16px' }} className="standardText">Insira um valor percentual válido, entre 0 e 100, sem casas decimais</h2> : ''
              }
              {constructionProgress.map((progressData) => {
                const { id, percentageCompleted, constructionPiece } = progressData;
                if (editData === true) {
                  return (
                    <div key={id}>
                      <input id={id} type="number" className="standardInput" placeholder={percentageCompleted} />
                    </div>
                  );
                }
                return (
                  <div key={id}>
                    <ConstructionProgress progress={percentageCompleted} part={constructionPiece} />
                  </div>
                );
              })}
            </div>

          </div> */}

        </div>

      </div>
    </div>
  );
}

export default GaleriaComponent;
