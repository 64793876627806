/* eslint-disable no-buffer-constructor */
/* eslint-disable no-useless-concat */
/* eslint-disable prefer-template */
/* eslint-disable quotes */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import base64 from 'base-64';
import fetchJsonp from 'fetch-jsonp';
import databaseUrl, { fetchDataFromLocalStorage } from '../../context/DatabaseConnection';
import Header from '../Header/Header';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import './EditarContaComponent.css';
import editarDado from '../../Media/EditarConta/editarDado.png';
import history from '../../context/history';

function EditarContaComponent() {
  const [emailEdit, triggerEmailEdit] = React.useState(false);
  const [email, changeEmail] = React.useState('');
  const [emailHasBeenChanged, triggerEmailChanged] = React.useState(false);

  const [passwordEdit, triggerPasswordEdit] = React.useState(false);
  const [password, changePassword] = React.useState('');
  const [passwordHasBeenChanged, triggerPasswordChanged] = React.useState(false);
  const [newPassword, changeNewPassword] = React.useState('');
  const [confirmNewPassword, changeConfirmNewPassword] = React.useState('');

  const { token, user } = fetchDataFromLocalStorage();

  function handleEditTrigger(elementToEdit) {
    switch (elementToEdit) {
      case 'email':
        if (emailEdit === true) {
          triggerEmailEdit(false);
          break;
        }
        triggerEmailEdit(true);
        break;
      default:
        break;
    }
  }

  function handleChange(elementChanged) {
    switch (elementChanged.name) {
      case 'email':
        changeEmail(elementChanged.value);
        break;
      case 'password':
        changePassword(elementChanged.value);
        break;
      case 'newPassword':
        changeNewPassword(elementChanged.value);
        break;
      case 'confirmNewPassword':
        changeConfirmNewPassword(elementChanged.value);
        break;
      default:
        break;
    }
  }

  async function handleClick(elementClicked) {
    switch (elementClicked) {
      case 'email':
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          if (emailHasBeenChanged === true) {
            window.alert('Email já foi alterado!');
          } else {
            const siengeResponse = await fetch(`https://api.sienge.com.br/fteinc/public/api/v1/customers/${user.siengeId}`, {
              method: 'PATCH',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${base64.encode('fteinc-kaizen:Otg55JSYc1XvlRAMGUY4ChPs6HiqaVBL')}`,
              },
              body: JSON.stringify({
                email,
              }),
            });
            if (siengeResponse.status === 204) {
              const response = await fetch(`${databaseUrl}/user/${user.id}`, {
                method: 'PUT',
                headers: { authorization: token, 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
              });
              const getToken = await fetch(`${databaseUrl}/login`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email,
                  password: user.password,
                }),
              });
              const loginRes = await getToken.json();
              if (response.status === 204) {
                triggerEmailChanged(true);
                user.email = email;
                localStorage.setItem('User', JSON.stringify(user));
                localStorage.setItem('Token', JSON.stringify(loginRes.token));
              }
            }
          }
        } else {
          window.alert('Endereço de email Inválido!');
        }
        break;
      default:
        break;
    }
  }

  function checkPassword() {
    if (password === user.password) {
      return triggerPasswordEdit(true);
    }
    return window.alert('Senha Incorreta');
  }

  async function alterPassword() {
    if (passwordHasBeenChanged === true) {
      return window.alert('Senha já foi alterada');
    }
    if (newPassword !== confirmNewPassword) {
      return window.alert('Senhas não são iguais');
    }
    const tryUpdatePassword = await fetch(`${databaseUrl}/password`, {
      method: 'PUT',
      headers: { authorization: token, 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: newPassword, isAdmin: user.admin }),
    });
    if (tryUpdatePassword.status !== 204) {
      return window.alert('Erro no sistema, senha não foi alterada');
    }
    const response = await fetch(`${databaseUrl}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password: newPassword,
        email: user.email,
      }),
    });
    const newUserAndToken = await response.json();
    localStorage.setItem('User', JSON.stringify(newUserAndToken.userData));
    localStorage.setItem('Token', JSON.stringify(newUserAndToken.token));
    triggerPasswordChanged(true);
  }

  if (user.admin === true) {
    return (
      <div className="EditarContaPage">
        <SidebarComponent user={user} />

        <div className="editarContaMain">
          <Header headerData={{ user }} />

          <div className="editarContaContent">

            <div className="standardHomeDiv">
              <h2 className="headingText">Alteração de senha</h2>
              {passwordEdit ? (
                <div className="editPasswordDiv">
                  <label id="newPasswordInput" htmlFor="newPasswordInput">
                    <p>Nova Senha</p>
                    <input name="newPassword" onChange={(e) => handleChange(e.target)} value={newPassword} style={{ marginTop: '22px' }} id="newPasswordInput" className="standardInput" />
                  </label>
                  <label id="newPasswordInput" htmlFor="newPasswordInput">
                    <p>Confirmar nova senha</p>
                    <input name="confirmNewPassword" onChange={(e) => handleChange(e.target)} value={confirmNewPassword} style={{ marginTop: '22px' }} id="newPasswordInput" className="standardInput" />
                  </label>
                  <button onClick={alterPassword} style={{ marginTop: '48px' }} type="button" className={passwordHasBeenChanged ? 'sucessBtn' : 'genericBtn'}>{passwordHasBeenChanged ? 'Senha Alterada' : 'Alterar Senha'}</button>
                </div>
              ) : (
                <div className="editPasswordDiv">
                  <h3 style={{ alignSelf: 'flex-start' }} className="standardText">Insira sua senha atual para alterá-la</h3>
                  <input name="password" onChange={(e) => handleChange(e.target)} value={password} style={{ marginTop: '0' }} className="standardInput" />
                  <p style={{ alignSelf: 'flex-start', marginTop: '20px' }} className="forgotPasswordText">Esqueceu a senha? Entre em contato com o financeiro</p>
                  <button onClick={checkPassword} style={{ marginTop: '48px' }} type="button" className="genericBtn">Confirmar</button>
                </div>
              )}
            </div>
          </div>
        </div>

      </div>
    );
  }

  return (
    <div className="EditarContaPage">
      <SidebarComponent user={user} />

      <div className="editarContaMain">
        <Header headerData={{ user }} />

        <div className="editarContaContent">
          <div className="standardHomeDiv">
            <h2 style={{ fontWeight: '600' }} className="standardText">{`Email Atual: ${user.email}`}</h2>
            <div>
              <div className="userDataAndChangeDataBtn">
                {
                emailEdit
                  ? <input name="email" onChange={(e) => handleChange(e.target)} value={email} style={{ marginTop: '0', width: '80%' }} className="standardInput" />
                  : <p style={{ fontSize: '14px', margin: '0' }}>{user.email}</p>
                }

                <div onClick={() => handleEditTrigger('email')} className="changeDataBtnAndText">
                  <img src={editarDado} alt="Clique aqui para editar" />
                  <p style={{ textDecoration: 'underline', margin: '0' }} className="billText orangeText">{emailEdit ? 'cancelar' : 'editar'}</p>
                </div>
              </div>

              {emailEdit ? <button onClick={() => handleClick('email')} style={{ marginTop: '20px' }} className={emailHasBeenChanged ? 'sucessBtn' : 'genericBtn'} type="button">{emailHasBeenChanged ? 'Alterações Salvas' : 'Salvar Alterações'}</button> : ''}
            </div>

          </div>

          <div className="standardHomeDiv">
            <h2 className="headingText">Alteração de senha</h2>
            {passwordEdit ? (
              <div className="editPasswordDiv">
                <label id="newPasswordInput" htmlFor="newPasswordInput">
                  <p>Nova Senha</p>
                  <input name="newPassword" onChange={(e) => handleChange(e.target)} value={newPassword} style={{ marginTop: '22px' }} id="newPasswordInput" className="standardInput" />
                </label>
                <label id="newPasswordInput" htmlFor="newPasswordInput">
                  <p>Confirmar nova senha</p>
                  <input name="confirmNewPassword" onChange={(e) => handleChange(e.target)} value={confirmNewPassword} style={{ marginTop: '22px' }} id="newPasswordInput" className="standardInput" />
                </label>
                <button onClick={alterPassword} style={{ marginTop: '48px' }} type="button" className={passwordHasBeenChanged ? 'sucessBtn' : 'genericBtn'}>{passwordHasBeenChanged ? 'Senha Alterada' : 'Alterar Senha'}</button>
              </div>
            ) : (
              <div className="editPasswordDiv">
                <h3 style={{ alignSelf: 'flex-start' }} className="standardText">Insira sua senha atual para alterá-la</h3>
                <input name="password" onChange={(e) => handleChange(e.target)} value={password} style={{ marginTop: '0' }} className="standardInput" />
                <p style={{ alignSelf: 'flex-start', marginTop: '20px' }} className="forgotPasswordText">Esqueceu a senha? Entre em contato com o financeiro</p>
                <button onClick={checkPassword} style={{ marginTop: '48px' }} type="button" className="genericBtn">Confirmar</button>
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  );
}

export default EditarContaComponent;
