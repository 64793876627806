/* eslint-disable consistent-return */
import React from 'react';
import './CriarUsuarioComponent.css';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import Header from '../Header/Header';
import databaseUrl from '../../context/DatabaseConnection';

function CriarUsuarioComponent({ user, token }) {
  function validateEmail(mail) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
  }

  async function handleClick() {
    const fullName = (document.querySelector('#fullName').value).toUpperCase();
    const email = (document.querySelector('#email').value);
    const cnpj = (document.querySelector('#cnpj').value);
    const siengeId = (document.querySelector('#siengeId').value);
    if (fullName === '' || email === '' || cnpj === '' || siengeId === '') {
      return window.alert('Preencha todos os campos corretamente antes de criar o cliente');
    }

    if ((validateEmail(email)) === false) {
      return window.alert('Insira um email válido para criar o cliente');
    }
    const reqBody = {
      fullName,
      email,
      cpf: cnpj,
      siengeId,
    };
    console.log(JSON.stringify(reqBody));
    const aaa = await fetch(`${databaseUrl}/users`, {
      method: 'POST',
      headers: {
        authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    });
    if (aaa.status !== 201) {
      return window.alert(await (aaa.json()));
    }

    window.alert('Usuario criado com sucesso!');

    window.location.reload();
  }

  return (
    <div className="CriarUsuarioPage">
      <SidebarComponent user={user} />

      <div className="CriarUsuarioComponent">

        <Header headerData={{ user }} />

        <div className="criarUsuarioMain">
          <div className="standardHomeDiv">
            <h1 className="headingText">Adicionar Cliente</h1>
            <div className="ClientDataInput">
              <p className="standardText">Todos os campos são obrigatórios</p>

              <p className="clientInputLabel">Nome Completo</p>
              <input className="standardInput" id="fullName" placeholder="Nome Completo" />

              <p className="clientInputLabel">Email</p>
              <input className="standardInput" id="email" placeholder="Email" />

              <p className="clientInputLabel">CPF ou CNPJ</p>
              <input type="number" className="standardInput" id="cnpj" placeholder="CPF ou CNPJ" />

              <p className="clientInputLabel">Número de Identificação no Sienge</p>
              <input type="number" className="standardInput" id="siengeId" placeholder="Número de identificação no sienge" />

              <button className="genericBtn" type="button" onClick={handleClick}>Criar Cliente</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default CriarUsuarioComponent;
