/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prefer-const */
import React from 'react';
import base64 from 'base-64';
import { formatNumber } from 'accounting';

import { fetchDataFromLocalStorage, getMonths } from '../../context/DatabaseConnection';
import Header from '../Header/Header';
import SidebarComponent from '../SidebarComponent/SidebarComponent';
import './PagamentosComponent.css';
import precoEscondido from '../../Media/Pagamentos/precoEscondido.png';
import precoRevelado from '../../Media/Pagamentos/precoRevelado.png';
import linkIcon from '../../Media/Pagamentos/linkIcon.png';
import loading from '../../Media/Blog/loading.gif';
import ExtractComponent from './ExtractComponent';

let paymentDone = false;
const delayedBills = [];

function MultiplosPagamentosComponent({ paymentData, payableParcels, fetchFinished }) {
  const { user } = fetchDataFromLocalStorage();
  const [revealPriceImg, changeRevealPriceImg] = React.useState(precoEscondido);
  const [currentBillPrice, changeCurrentBillPrice] = React.useState('⚫ ⚫ ⚫ ⚫');

  const [totalPayed, changeTotalPayed] = React.useState('⚫ ⚫ ⚫ ⚫');
  const [revealTotalPayedImg, changeTotalPayedImg] = React.useState(precoEscondido);
  const [userInfo, setUserInfo] = React.useState(false);
  const [delayedBill, setDelayedBill] = React.useState(false);
  const [seeExtract, setSeeExtract] = React.useState(false);

  React.useEffect(() => {
    async function fetchUserInfo() {
      const response = await fetch(`https://api.sienge.com.br/fteinc/public/api/v1/customers/${user.siengeId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${base64.encode('fteinc-kaizen:Otg55JSYc1XvlRAMGUY4ChPs6HiqaVBL')}`,
        },
      });
      const res = await response.json();
      setUserInfo(res);
    }
    fetchUserInfo();
  }, []);

  if (fetchFinished === false || payableParcels === false || paymentData.length === 0) {
    return (
      <div className="PagamentosPage">
        <SidebarComponent user={user} />

        <div className="pagamentosMain">

          <Header headerData={{ user }} />

          <div className="pagamentosContent">
            <div>
              <div className="standardHomeDiv">
                <h1 className="headingText">Carregando</h1>
                <img marginTop={35} src={loading} width={400} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getAllBills() {
    const bills = {
      paidInstallments: [],
      dueInstallments: [],
      payableInstallments: [],
    };
    paymentData.forEach((lote) => {
      if (lote.paidInstallments !== undefined) {
        bills.paidInstallments = [...bills.paidInstallments, ...lote.paidInstallments];
      }
      if (lote.dueInstallments !== undefined) {
        bills.dueInstallments = [...bills.dueInstallments, ...lote.dueInstallments];
      }
      if (lote.payableInstallments !== undefined) {
        bills.payableInstallments = [...bills.payableInstallments, ...lote.payableInstallments];
      }
    });
    if (bills.paidInstallments.length === 0) {
      bills.paidInstallments = undefined;
    }
    if (bills.dueInstallments.length === 0) {
      bills.dueInstallments = undefined;
    }
    if (bills.payableInstallments.length === 0) {
      bills.payableInstallments = undefined;
    }
    return bills;
  }

  const allBills = getAllBills();

  if (allBills.dueInstallments !== undefined && delayedBill !== true) {
    setDelayedBill(true);
    if (allBills.length !== delayedBills.length) {
      delayedBills.push(...allBills.dueInstallments);
    }
  }

  const userData = {
    currentPayment: {},
  };
  function saveUserData() {
    if (payableParcels.length !== 0) {
      let sumOfCurrentBillsValue = 0;
      let dueDate = '';
      let indexerName = '';
      payableParcels.forEach((lote) => {
        sumOfCurrentBillsValue += lote.payableInstallments[0].adjustedValue;
        dueDate = lote.payableInstallments[0].dueDate;
        indexerName = lote.payableInstallments[0].indexerName;
      });
      userData.currentPayment = {
        adjustedValue: sumOfCurrentBillsValue,
        dueDate,
        indexerName,
      };
    } else {
      userData.currentPayment = paymentData[0].paidInstallments[0];
      paymentDone = true;
    }
  }
  saveUserData();

  let [billPrice, billCents] = userData.currentPayment.adjustedValue.toString().split('.');
  let [currentBillYear, currentBillMonth, currentBillDay] = userData.currentPayment.dueDate.split('-');
  let firstDivHeader = 'Próximo pagamento';
  let CurrentBillsMonthString = getMonths[currentBillMonth - 1];
  let dueMonth = CurrentBillsMonthString;
  let datePaid = `vence em ${currentBillDay} ${dueMonth.substring(0, 3)} ${currentBillYear}`;
  let getDate = `${currentBillDay} ${dueMonth.substring(0, 3)} ${currentBillYear}`;
  let firstDivDatePaid = <p className="biggerText">vence em <strong>{`${currentBillDay} ${dueMonth.substring(0, 3)} ${currentBillYear}`.toUpperCase()}</strong></p>;

  if (delayedBill === true && firstDivHeader === 'Próximo pagamento') {
    [billPrice, billCents] = delayedBills[0].adjustedValue.toString().split('.');
    [currentBillYear, currentBillMonth, currentBillDay] = delayedBills[0].dueDate.split('-');
    firstDivHeader = 'Pagamento em atraso';
    CurrentBillsMonthString = getMonths[currentBillMonth - 1];
    dueMonth = CurrentBillsMonthString;
    datePaid = `venceu em ${currentBillDay} ${dueMonth.substring(0, 3)} ${currentBillYear}`;
    firstDivDatePaid = <p className="biggerText">venceu em <strong>{`${currentBillDay} ${dueMonth.substring(0, 3).toUpperCase()} ${currentBillYear}`.toUpperCase()}</strong></p>;
  }

  const currentBillPriceNumeral = `${formatNumber(billPrice, undefined, '.')},${billCents.substr(0, 2)}`;

  let indexToAdjustValue = userData.currentPayment.indexerName;

  let allParcels = 0;
  let payedParcels = 0;

  paymentData.forEach((lote) => {
    if (lote.paidInstallments !== undefined) {
      allParcels += lote.paidInstallments.length;
      payedParcels += lote.paidInstallments.length;
    }
    if (lote.payableInstallments !== undefined) {
      allParcels += lote.payableInstallments.length;
    }
    if (lote.dueInstallments !== undefined) {
      allParcels += lote.dueInstallments.length;
    }
  });

  function fetchBillsAndGetResume() {
    const billsToFetch = [];
    const { dueInstallments } = allBills;
    if (dueInstallments === undefined || dueInstallments.length === 0) {
      billsToFetch.push(...allBills.paidInstallments, allBills.payableInstallments[0]);
    } else {
      dueInstallments.forEach((bill) => {
        bill.expired = true;
      });
      billsToFetch.push(...allBills.paidInstallments, ...dueInstallments, allBills.payableInstallments[0]);
    }
    const lengthToCutArray = billsToFetch.length - 6;
    billsToFetch.splice(0, lengthToCutArray);
    let isExpired = false;

    billsToFetch.forEach((bill) => {
      if (bill.expired !== undefined && bill.expired === true) {
        isExpired = true;
      }
    });

    if (isExpired === true) {
      return billsToFetch.map((bill) => {
        const [, billMonth] = bill.dueDate.split('-');
        const billsMonthString = getMonths[billMonth - 1].substring(0, 3);
        if (bill.expired !== undefined && bill.expired === true) {
          return (
            <div key={bill.dueDate} className="billData">
              <p className="billText redText"><strong>Atrasada</strong></p>
              <div className="billGraph strongRed" />
              <p className="billMonthText">{billsMonthString}</p>
            </div>
          );
        }
        return (
          <div key={bill.dueDate} className="billData">
            <p className="billText" />
            <div className="billGraph fadedOrange" />
            <p className="billMonthText">{billsMonthString}</p>
          </div>
        );
      });
    }

    return billsToFetch.map((bill) => {
      const [, billMonth] = bill.dueDate.split('-');
      const billsMonthString = getMonths[billMonth - 1].substring(0, 3);
      if (bill.receipts === undefined) {
        return (
          <div key={bill.dueDate} className="billData">
            <p className="billText"><strong>Em aberto</strong></p>
            <div className="billGraph strongOrange" />
            <p className="billMonthText">{billsMonthString}</p>
          </div>
        );
      }
      return (
        <div key={bill.dueDate} className="billData">
          <p className="billText" />
          <div className="billGraph fadedOrange" />
          <p className="billMonthText">{billsMonthString}</p>
        </div>
      );
    });
  }

  function getTotalPayed() {
    let totalPayedNumeral = 0;
    allBills.paidInstallments.forEach((billPayed) => {
      totalPayedNumeral += billPayed.adjustedValue;
    });
    const [bigNumber, afterDotNumber] = totalPayedNumeral.toString().split('.');
    const totalNumber = formatNumber(bigNumber, undefined, '.');
    return (`${totalNumber},${afterDotNumber.substr(0, 2)}`);
  }

  async function handleRevealTotalPriceClick() {
    if (totalPayed === '⚫ ⚫ ⚫ ⚫') {
      changeTotalPayed(getTotalPayed());
      changeTotalPayedImg(precoRevelado);
    } else {
      changeTotalPayed('⚫ ⚫ ⚫ ⚫');
      changeTotalPayedImg(precoEscondido);
    }
  }

  async function handleRevealPriceClick() {
    if (currentBillPrice === '⚫ ⚫ ⚫ ⚫') {
      changeCurrentBillPrice(currentBillPriceNumeral);
      changeRevealPriceImg(precoRevelado);
    } else {
      changeCurrentBillPrice('⚫ ⚫ ⚫ ⚫');
      changeRevealPriceImg(precoEscondido);
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async function handleClick(clickedElement) {
    const { email } = userInfo;
    const username = user.fullName.split(' ')[0].toLowerCase();
    switch (clickedElement) {
      case 'mailBills':
        const mailBillsResponse = await fetch('https://api.sienge.com.br/fteinc/public/api/v1/email-current-debit-balance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${base64.encode('fteinc-kaizen:Otg55JSYc1XvlRAMGUY4ChPs6HiqaVBL')}`,
          },
          body: JSON.stringify({
            customerId: user.siengeId,
            email,
            subject: 'O Seu Extrato do Carmel.',
            message: `Olá ${capitalizeFirstLetter(username)}, \n \n Conforme solicitado, segue aqui uma cópia em PDF do extrato da sua conta.`,
            fileName: `Extrato Carmel - ${capitalizeFirstLetter(username)}`,
          }),
        });
        break;
      case 'boletoEmail':
        const aa = payableParcels.every(async (lote) => {
          const { billReceivableId } = lote;
          const { installmentId } = lote.payableInstallments[0];
          const res = await fetch('https://api.sienge.com.br/fteinc/public/api/v1/payment-slip-notification', {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${base64.encode('fteinc-kaizen:Otg55JSYc1XvlRAMGUY4ChPs6HiqaVBL')}`,
            },
            body: JSON.stringify({
              receivableBillId: billReceivableId,
              installmentId,
              emailCustomer: email,
              emailTitle: 'Seu Boleto do Carmel.',
              emailBody: `Olá ${capitalizeFirstLetter(username)}, \n \n Conforme solicitado, segue aqui uma cópia em PDF do seu boleto para o mês de ${getMonths[currentBillMonth - 1]}`,
            }),
          });
          if (res.status) {
            return false;
          }
        });
        if (aa === false) {
          return window.alert('Nós emitimos nossos boletos no começo do mês, se você não consegue baixar o boleto seguinte é porquê ainda não o emitimos. \n Caso tenha dúvidas entre em contato com o financeiro.');
        }
        break;
      case 'faleConosco':
        history.push('/atendimento');
        break;
      default:
        break;
    }
  }

  function handleConsultClick() {
    if (indexToAdjustValue === 'IPCA') {
      return window.open('https://www.ibge.gov.br/estatisticas/economicas/precos-e-custos/9256-indice-nacional-de-precos-ao-consumidor-amplo.html');
    }
    return window.open('http://indiceseconomicos.secovi.com.br/indicadormensal.php?idindicador=58');
  }

  return (
    <div className="PagamentosPage">
      <SidebarComponent user={user} />

      <div className="pagamentosMain">

        <Header headerData={{ user }} />

        <div className="pagamentosContent">
          {paymentDone ? (
            <div>
              <div className="standardHomeDiv">
                <div className="lateralBorderAndText">
                  <div className="strongGreen" />
                  <h4 className="headingText">Todas as contas quitadas!</h4>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="standardHomeDiv">
                <div className="lateralBorderAndText">
                  <div className={delayedBill === true ? 'strongRed' : 'strongOrange'} />
                  <h4 className="headingText">{firstDivHeader}</h4>
                </div>
                <div className="billPriceAndValidation">
                  <div className="valueAndRevealValueBtn">
                    <img onClick={handleRevealPriceClick} src={revealPriceImg} alt="Clique aqui para mostrar o valor" />
                    <p className="standardText">{currentBillPrice}</p>
                  </div>
                  {firstDivDatePaid}
                </div>
                {delayedBill === true ? <div className="twoButtonsPaymentDiv"><p className="standardText">Entre em contato com a Carmel para regularizar seu pagamento</p></div>
                  : (
                    <div className="twoButtonsPaymentDiv">
                      <button onClick={() => handleClick('boletoEmail')} type="button" className="genericBtn">
                        Enviar Boletos Por Email
                      </button>
                    </div>
                  )}
                {delayedBill === false ? <p style={{ marginTop: '15px', fontWeight: '600', textDecoration: 'font-style' }} className="standardText">Seu email: {user.email}</p> : '' }
              </div>

              {seeExtract ? <ExtractComponent setSeeExtract={setSeeExtract} /> : (
                <div className="standardHomeDiv">
                  <h3 className="headingText">Resumo</h3>
                  <div className="standardText fixSmallIcon"><p>conta de <strong>{dueMonth}</strong></p><span className="smallIcon">⚫</span><p>{datePaid}</p></div>
                  <p style={{ marginTop: '28px' }} className="standardText">R$<span className="billValueText">{currentBillPriceNumeral}</span></p>
                  <div className="allBillsDiv">
                    {fetchBillsAndGetResume()}
                  </div>
                  <button onClick={() => handleClick('mailBills')} style={{ marginTop: '32px' }} type="button" className="genericBtn">Enviar Conta por Email</button>
                  <button style={{ marginTop: '15px' }} type="button" onClick={() => { setSeeExtract(true); }} className="genericBtn">
                    Resumo Consolidado do Extrato
                  </button>
                  <button type="button" className="soonerBtn">
                    <p className="soonerDecorationText alignToBtnEndText">
                      Em breve
                    </p>
                    <p className="soonerBtnText">
                      Enviar Extrato De IR Por Email
                    </p>
                  </button>
                </div>
              )}

            </div>
          )}

          <div>

            <div className="standardHomeDiv">
              <h3 className="headingText">Total pago</h3>
              <div style={{ marginBottom: '0' }} className="billPriceAndValidation">
                <div className="valueAndRevealValueBtn">
                  <img onClick={handleRevealTotalPriceClick} src={revealTotalPayedImg} alt="Clique aqui para mostrar o valor" />
                  <p className="standardText">{totalPayed}</p>
                </div>
                <p className="standardText greenText">{`${payedParcels}/${allParcels}`} parcelas pagas</p>
              </div>
            </div>

            <div className="standardHomeDiv">
              <h3 className="headingText marginBottom">Informações do Rendimento</h3>
              <p className="standardText marginBottom">N° total de parcelas: <strong>{allParcels}</strong></p>
              <p style={{ marginBottom: '4px' }} className="standardText"><strong>Reajustes mensais de acordo com {indexToAdjustValue}*</strong></p>
              <p className="smallerText marginBottom">* Índice {indexToAdjustValue === 'IPCA' ? 'Nacional de Preços ao Consumidor Amplo' : 'Geral de Preços do Mercado'}</p>
              <div onClick={handleConsultClick} className="paymentTextAndImage">
                <img src={linkIcon} alt="" />
                <p><strong>Consultar {indexToAdjustValue} do último mês</strong></p>
              </div>
            </div>

            <div className="standardHomeDiv">
              <h3 className="headingText">Dúvidas Frequentes</h3>
              <ul>
                <li>
                  <h4 className="standardText">Antecipar pagamento de parcelas</h4>
                  <p>Caso esteja no modelo IGP-M, ocorrerá a descapitalização dos juros cobrados na proposta de venda, ou seja, serão abatidos</p>
                </li>

                <li>
                  <h4 className="standardText">Alteração da amortização</h4>
                  <p>Caso você queira alterar o índice de amortização, entre em contato com o financeiro da Carmel para simulação do novo plano e criação de novas parcelas</p>
                </li>

                <li>
                  <h4 className="standardText">Atraso do pagamento do boleto</h4>
                  <p>Se o atraso foi maior que 30 dias, o financeiro da Carmel deverá ser contatado para emissão de um novo boleto com valores atualizados</p>
                </li>
              </ul>
              <button style={{ marginTop: '32px' }} type="button" className="genericBtn">Fale Conosco</button>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
}

export default MultiplosPagamentosComponent;
