import React from 'react';
import HelpComponent from '../components/HelpComponent/HelpComponent';
import { fetchDataFromLocalStorage } from '../context/DatabaseConnection';

function HelpPage() {
  const { user } = fetchDataFromLocalStorage();

  return (
    <div>
      <HelpComponent user={user} />
    </div>
  );
}

export default HelpPage;
