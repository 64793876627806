import React from 'react';
import databaseUrl from '../../context/DatabaseConnection';
import loading from '../../Media/Blog/loading.gif';

function ImageComponent({ filename, modalId }) {
  const [image, setImage] = React.useState(loading);
  React.useEffect(() => {
    async function fetchData() {
      const data = await fetch(`${databaseUrl}/gallery/${filename.filename}`);
      return data;
    }
    if (filename !== undefined) {
      fetchData().then((res) => {
        setImage(res.url);
      });
    }
  }, [filename]);

  return <img className="smallerImage" id={modalId} src={image} alt="imagem da galeria" />;

  // return <img className="imageComponent" id={modalId} src={image} alt="imagem da galeria" />;
}

export default ImageComponent;
