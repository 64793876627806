import React from 'react';
import { useParams } from 'react-router';
import PostComponent from '../components/Blog/PostComponent';
import databaseUrl from '../context/DatabaseConnection';

function BlogPost() {
  const [postData, setPostData] = React.useState(undefined);

  const { id } = useParams();

  React.useEffect(() => {
    async function fetchData() {
      const data = await fetch(`${databaseUrl}/post/${id}`);
      const response = await data.json();
      return response;
    }
    fetchData().then(([res]) => {
      setPostData(res);
    });
  }, []);

  return (
    <div>
      <PostComponent postData={postData} />
    </div>
  );
}

export default BlogPost;
