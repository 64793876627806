import React from 'react';
import GaleriaComponent from '../components/Galeria/GaleriaComponent';

function GaleriaPage() {
  return (
    <div>
      <GaleriaComponent />
    </div>
  );
}

export default GaleriaPage;
