/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React from 'react';
import databaseUrl from '../../context/DatabaseConnection';
import loading from '../../Media/Blog/loading.gif';
import readTime from '../../Media/Blog/readTime.png';
import date from '../../Media/Blog/date.png';
import postCreator from '../../Media/Blog/postCreator.png';
import './PostComponent.css';

function PostComponent({ postData }) {
  const [postImage, loadPostImage] = React.useState(loading);
  const [postDate, changePostDate] = React.useState('');
  const [postText, changePostText] = React.useState(['carregando']);
  const [postState, updatePostState] = React.useState('smaller');

  React.useEffect(() => {
    async function fetchData() {
      const data = await fetch(`${databaseUrl}/images/${postData.image}`);
      return data;
    }
    console.log('aqui');
    console.log(postData);
    if (postData !== undefined) {
      fetchData().then((res) => {
        if (res.status === 404) {
          return loadPostImage('');
        }
        return loadPostImage(res.url);
      });

      const dateArray = postData.published.split('-');
      changePostDate(`${(dateArray[2]).slice(0, 2)}.${dateArray[1]}.${dateArray[0]}`);
      changePostText(postData.content.split(/\r?\n|\r|\n/g));
    }
  }, [postData]);

  if (postData === undefined) {
    return (
      <div>
        <h1>Carregando dados do post</h1>
      </div>
    );
  }

  let postContent = <p className="standardText">{postText[0]}</p>;

  if (postState === 'expanded') {
    postContent = postText.map((text) => {
      if (text !== '') {
        return <p key={text} className="standardText multipleBlogTexts">{text}</p>;
      }
    });
  }

  async function handleSeeMoreClick() {
    const element = document.querySelector('.postAndText');
    const [imageElement] = element.childNodes;
    if (element.className === 'postAndText expanded') {
      // caso elemento já esteja expandido
      element.className = 'postAndText';
      updatePostState('smaller');

      imageElement.style.cssText = '';
    } else {
      // caso elemento ainda não tenha sido expandido
      element.className += ' expanded';
      updatePostState('expanded');

      imageElement.style.cssText = 'min-width: 100%';
    }
  }

  return (
    <div className="BlogPost">
      <h1 className="headingText">{postData.title}</h1>

      <div className="postHeaders">
        <div className="textAndImage">
          <img src={postCreator} alt="" />
          <p className="yellowUnderlineText">{postData.postAuthor}</p>
        </div>
        <div className="textAndImage">
          <img src={date} alt="" />
          <p className="yellowUnderlineText">{postDate}</p>
        </div>
        <div className="textAndImage">
          <img src={readTime} alt="" />
          <p className="yellowUnderlineText">{`${postData.readTime} min de leitura`}</p>
        </div>
      </div>

      <div className="postAndText">
        <div className="postImage">
          <img alt="" src={postImage} />
        </div>
        {postContent}
      </div>

      <button type="button" onClick={handleSeeMoreClick} className="genericBtn standardText">
        {(postState === 'smaller') ? 'Ver Mais' : 'Ver Menos'}
      </button>
    </div>
  );
}

export default PostComponent;
