/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
import React from 'react';
import base64 from 'base-64';
import PagamentosComponent from '../components/Pagamentos/PagamentosComponent';
import { fetchDataFromLocalStorage } from '../context/DatabaseConnection';
import MultiplosPagamentosComponent from '../components/Pagamentos/MultiplosPagamentosComponent';
import SidebarComponent from '../components/SidebarComponent/SidebarComponent';
import Header from '../components/Header/Header';
import loading from '../Media/Blog/loading.gif';
import '../components/Pagamentos/loading.css';

function PagamentosPage() {
  const { user } = fetchDataFromLocalStorage();
  const [debitBalance, setDebitBalance] = React.useState([]);
  const [finishedFetching, setFinishedFetching] = React.useState(false);
  const [payableParcels, setPayableParcels] = React.useState(false);

  React.useEffect(() => {
    async function fetchContent() {
      const response = await fetch(`https://api.sienge.com.br/fteinc/public/api/v1/customers/${user.siengeId}`, {
        mode: 'cors',
        headers: {
          Authorization: `Basic ${base64.encode('fteinc-kaizen:Otg55JSYc1XvlRAMGUY4ChPs6HiqaVBL')}`,
        },
      });
      const res = await response.json();
      const { cpf, cnpj } = res;
      let variableToUse = `?cpf=${cpf}`;
      // eslint-disable-next-line no-unused-expressions
      cpf === undefined ? variableToUse = `?cnpj=${cnpj}` : variableToUse = `?cpf=${cpf}`;
      const debtBalance = await fetch(`https://api.sienge.com.br/fteinc/public/api/v1/current-debit-balance${variableToUse}`, {
        mode: 'cors',
        headers: {
          Authorization: `Basic ${base64.encode('fteinc-kaizen:Otg55JSYc1XvlRAMGUY4ChPs6HiqaVBL')}`,
        },
      });
      const returnValue = await debtBalance.json();
      return returnValue;
    }

    setTimeout(async () => {
      const response = await fetchContent();
      setDebitBalance(response.results);
    }, 500);
  }, []);

  function savePayableInstallments() {
    const aaa = debitBalance.filter((paymentRegistered) => {
      if (paymentRegistered !== undefined) {
        if (paymentRegistered.payableInstallments !== undefined) {
          return paymentRegistered;
        }
      }
    });
    if (debitBalance.length !== 0) {
      setPayableParcels(aaa);
      setFinishedFetching(true);
    }
  }

  if (debitBalance.length === 0 || finishedFetching === false) {
    savePayableInstallments();
    return (
      <div className="PagamentosPage">
        <SidebarComponent user={user} />

        <div className="pagamentosMain">

          <Header headerData={{ user }} />

          <div className="pagamentosContent">
            <div>
              <div className="standardHomeDiv">
                <h1 className="headingText">Carregando</h1>
                <img className="loadingGifStyle" src={loading} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (typeof payableParcels === 'object' && payableParcels.length > 1) {
    return <MultiplosPagamentosComponent paymentData={debitBalance} payableParcels={payableParcels} fetchFinished={finishedFetching} />;
  }

  return (
    <PagamentosComponent paymentData={debitBalance} payableParcels={payableParcels} fetchFinished={finishedFetching} />
  );
}

export default PagamentosPage;
