/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './SidebarComponent.css';
import carmelSidebarLogo from '../../Media/Sidebar/carmelSidebarLogo.png';
import notification from '../../Media/Sidebar/notification.png';
import notificationActive from '../../Media/Header/notificationActive.svg';
import menuNavbar from '../../Media/Sidebar/MenuNavbar.png';
import carmelText from '../../Media/Sidebar/carmelText.png';
import tutoriais from '../../Media/Sidebar/questionMark.svg';
import atendimento from '../../Media/Sidebar/atendimento.svg';
import carmelBlog from '../../Media/Sidebar/carmelBlog.svg';
import clienteCarmel from '../../Media/Sidebar/clienteCarmel.svg';
import editarConta from '../../Media/Sidebar/editarConta.svg';
import galeria from '../../Media/Sidebar/galeria.svg';
import home from '../../Media/Sidebar/home.svg';
import history from '../../context/history';
import pagamentos from '../../Media/Sidebar/pagamentos.svg';
import logoff from '../../Media/Sidebar/logoff.svg';
import logoKaizen from '../../Media/Sidebar/logoKaizen.svg';
import { fetchDataFromLocalStorage } from '../../context/DatabaseConnection';
import NPSComponent from '../Header/NpsComponent';

function SidebarComponent({ user }) {
  const [notificationIcon, setNotificationIcon] = React.useState(notification);
  const [npsState, setNpsState] = React.useState(false);

  const { NPSpopupState, token } = fetchDataFromLocalStorage();

  if (NPSpopupState === null && notificationIcon !== notificationActive) {
    setNotificationIcon(notificationActive);
  }

  function handleNotificationClick() {
    if (notificationIcon !== notificationActive) {
      return;
    }
    if (npsState === true) {
      setNpsState(false);
    } else setNpsState(true);
  }

  React.useEffect(() => {
    const [, pathname] = history.location.pathname.split('/');
    const currentPage = document.getElementById(pathname);
    if (currentPage !== null && currentPage !== undefined) {
      currentPage.style.backgroundColor = '#FAA123';
      const sideItens = Array.from(document.getElementsByClassName('sidebarItem'));
      sideItens.forEach((item) => {
        item.addEventListener('mouseover', (e) => {
          if (Array(e.target.children)[0].length === 2) {
            currentPage.style.backgroundColor = '#FFFFFF';
            e.target.style.backgroundColor = '#FAA123';
          } else {
            currentPage.style.backgroundColor = '#FFFFFF';
            e.target.parentNode.style.backgroundColor = '#FAA123';
          }
        });

        item.addEventListener('mouseleave', (e) => {
          if (Array(e.target.children)[0].length === 2) {
            currentPage.style.backgroundColor = '#FAA123';
            e.target.style.backgroundColor = '#FFFFFF';
          } else {
            currentPage.style.backgroundColor = '#FAA123';
            e.target.parentNode.style.backgroundColor = '#FFFFFF';
          }
          if (e.target.id === pathname) {
            e.target.style.backgroundColor = '#FAA123';
          }
        });
      });
    }
  }, []);

  function handleClick() {
    const sidebarItens = Array.from(document.getElementsByClassName('sidebarItem'));

    const showOnMenuClick = document.querySelector('.showOnMenuClick');

    const mobileNav = document.querySelector('.MobileNav');

    sidebarItens.forEach((item) => {
      if (item.style.display === 'flex') {
        mobileNav.style.justifyContent = 'space-between';
        mobileNav.style.boxShadow = '0px 4px 29px rgba(0, 0, 0, 0.25)';
        showOnMenuClick.style.display = 'none';
        item.style.display = 'none';
        item.style.width = '0';
        item.style.height = '0';
        return;
      }
      mobileNav.style.justifyContent = 'flex-start';
      showOnMenuClick.style.display = 'inline';
      mobileNav.style.boxShadow = '0px 0px 0px';
      item.style.display = 'flex';
      item.style.width = '100%';
      item.style.height = 'auto';
    });

    const hideOnMenuClick = Array.from(document.getElementsByClassName('hideOnMenuClick'));
    hideOnMenuClick.forEach((item) => {
      if (item.style.display === 'none') {
        item.style.display = 'inline';
        return;
      }
      item.style.display = 'none';
    });
  }

  function handleItemClick(e) {
    if (Array(e.children)[0].length > 0) {
      history.push(e.id);
    } else {
      history.push(e.parentNode.id);
    }
  }

  function handleLogoff() {
    history.push('/');
  }

  if (user.admin === true) {
    return (
      <div className="SidebarComponent">

        <div onClick={() => { history.push('/home'); }} className="sidebarHeader">
          <img src={carmelSidebarLogo} alt="carmelLogo" />
          <img src={carmelText} alt="CARMEL" />
        </div>

        <div className="MobileNav">
          <img onClick={() => handleClick()} src={menuNavbar} id="menuNavbar" alt="Menu de navegação" />
          <img src={carmelSidebarLogo} className="hideOnMenuClick" alt="Carmel Logo" />
          <img src={notification} className="hideOnMenuClick" alt="Notificações" />
          <p className="showOnMenuClick">MENU</p>
        </div>

        <div id="home" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
          <img src={home} alt="" />
          <p>Home</p>
        </div>

        <div id="suporte" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
          <img src={logoKaizen} alt="" />
          <p>Suporte Kaizen</p>
        </div>

        <div id="clientes" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
          <img src={clienteCarmel} alt="" />
          <p>Clientes Carmel</p>
        </div>

        <div id="galeria" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
          <img src={galeria} alt="" />
          <p>Galeria</p>
        </div>

        <div id="atendimento" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
          <img src={atendimento} alt="" />
          <p>Atendimento</p>
        </div>

        <div id="blog" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
          <img src={carmelBlog} alt="" />
          <p>Carmel Blog</p>
        </div>

        <div id="editarConta" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
          <img src={editarConta} alt="" />
          <p>Editar Conta</p>
        </div>

        <div id="help" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
          <img src={tutoriais} alt="" />
          <p>Tutoriais App</p>
        </div>

        <div id="logoff" onClick={() => handleLogoff()} className="sidebarItem">
          <img src={logoff} alt="" />
          <p>Sair</p>
        </div>

      </div>
    );
  }

  return (
    <div className="SidebarComponent">

      <div onClick={() => { history.push('/home'); }} className="sidebarHeader">
        <img src={carmelSidebarLogo} alt="carmelLogo" />
        <img src={carmelText} alt="CARMEL" />
      </div>

      <div className="MobileNav">
        <img onClick={() => handleClick()} src={menuNavbar} id="menuNavbar" alt="Menu de navegação" />
        <img src={carmelSidebarLogo} className="hideOnMenuClick" alt="Carmel Logo" />
        <img className="hideOnMenuClick" onClick={handleNotificationClick} src={notificationIcon} alt="Notificações" />
        <NPSComponent npsState={npsState} setNpsState={setNpsState} token={token} />
        <p className="showOnMenuClick">MENU</p>
      </div>

      <div id="home" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
        <img src={home} alt="" />
        <p>Home</p>
      </div>

      <div id="pagamentos" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
        <img src={pagamentos} alt="" />
        <p>Pagamentos</p>
      </div>

      <div id="galeria" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
        <img src={galeria} alt="" />
        <p>Galeria</p>
      </div>

      <div id="atendimento" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
        <img src={atendimento} alt="" />
        <p>Atendimento</p>
      </div>

      <div id="blog" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
        <img src={carmelBlog} alt="" />
        <p>Carmel Blog</p>
      </div>

      <div id="editarConta" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
        <img src={editarConta} alt="" />
        <p>Editar Conta</p>
      </div>

      <div id="help" onClick={(e) => handleItemClick(e.target)} className="sidebarItem">
        <img src={tutoriais} alt="" />
        <p>Tutoriais App</p>
      </div>

      <div id="logoff" onClick={() => handleLogoff()} className="sidebarItem">
        <img src={logoff} alt="" />
        <p>Sair</p>
      </div>

    </div>
  );
}

export default SidebarComponent;
