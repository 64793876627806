import React from 'react';
import BlogComponent from '../components/Blog/BlogComponent';

function BlogPage() {
  return (
    <BlogComponent />
  );
}

export default BlogPage;
