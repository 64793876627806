import React from 'react';
import LoginComponent from '../components/Login/LoginComponent';

function LoginPage() {
  return (
    <div className="Page">
      <LoginComponent />
    </div>
  );
}

export default LoginPage;
