/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import databaseUrl, { fetchDataFromLocalStorage } from '../../../context/DatabaseConnection';
// import AppContext from '../../../context/AppContext';
import './CriarPost.css';

let hiddenClass = 'hidden';

function CriarPostComponent({ rascunho }) {
  const [postTitle, changePostTitle] = React.useState('');
  const [postBody, changePostBody] = React.useState('');
  const [postAuthor, changePostAuthor] = React.useState('');
  // const [postDate, changePostDate] = React.useState('');
  const [postReadTime, changePostReadTime] = React.useState('');
  const [image, setImage] = React.useState(undefined);

  function checkData() {
    const selectedImage = document.getElementById('imageInput').files;
    if (postTitle !== '' && postBody !== '' && postAuthor !== '' && postReadTime !== '' && selectedImage[0] !== undefined) {
      return false;
    }
    return true;
  }

  function checkImage() {
    if (image === undefined) {
      const selectedImage = document.getElementById('imageInput').files;
      if (selectedImage[0] !== undefined) {
        hiddenClass = '';
        const url = URL.createObjectURL(selectedImage[0]);
        setImage(url);
      }
    }
  }

  async function handleChange(element) {
    switch (element.name) {
      case 'title':
        changePostTitle(element.value);
        break;
      case 'body':
        changePostBody(element.value);
        break;
      case 'author':
        changePostAuthor(element.value);
        break;
      // case 'date':
      //   changePostDate(element.value);
      //   break;
      case 'readTime':
        if (isNaN(element.value)) {
          break;
        }
        changePostReadTime(element.value);
        break;
      default:
        break;
    }
    checkData();
  }

  async function handleClick() {
    const disabled = checkData();
    if (disabled === true) {
      return window.alert('Preencha os campos e selecione uma imagem para criar o Post');
    }
    const localStorageToken = fetchDataFromLocalStorage().token;
    const selectedImage = document.getElementById('imageInput').files[0];

    const formData = new FormData();
    formData.append('file', selectedImage);
    formData.append('title', postTitle);
    formData.append('content', postBody);
    formData.append('postAuthor', postAuthor);
    formData.append('readTime', parseInt(postReadTime, 10));

    const response = await fetch(`${databaseUrl}/posts`, {
      method: 'POST',
      headers: {
        authorization: localStorageToken,
      },
      body: formData,
    });

    await response.json();
    window.location.reload();
    return '';
  }

  function removeImage() {
    document.getElementById('imageInput').value = '';
    setImage(undefined);
    hiddenClass = 'hidden';
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      checkImage();
    }, 1000);

    if (rascunho !== undefined) {
      changePostTitle(rascunho.title);
      changePostBody(rascunho.content);
      changePostReadTime(rascunho.readTime);
      changePostAuthor(rascunho.author);
    }

    return () => {
      clearInterval(interval);
    };
  }, [rascunho]);

  function handleSaveTextClick() {
    const selectedImage = document.getElementById('imageInput').files[0];
    const rascunhosSalvos = JSON.parse(localStorage.getItem('blogPostsDrafts'));
    if (selectedImage !== undefined) {
      return window.alert('Remova a imagem selecionada antes de salvar o rascunho, ela não pode ser salva como parte do rascunho!');
    }
    const dataToSave = {
      title: postTitle,
      content: postBody,
      author: postAuthor,
      readTime: postReadTime,
    };
    if (postTitle === '') {
      return window.alert('Defina um título antes de salvar o rascunho!');
    }
    if (rascunhosSalvos === null || typeof rascunhosSalvos !== 'object') {
      localStorage.setItem('blogPostsDrafts', JSON.stringify([dataToSave]));
    } else {
      const rascunhoFilter = rascunhosSalvos.filter((rs) => rs.title === postTitle);
      if (rascunhoFilter.length !== 0) {
        return window.alert('Você já tem um rascunho salvo com esse título, apague-o ou mude o título!');
      }
      rascunhosSalvos.push(dataToSave);
      localStorage.setItem('blogPostsDrafts', JSON.stringify(rascunhosSalvos));
    }
    return window.location.reload();
  }

  function handleDeleteDraftText() {
    const rascunhosSalvos = JSON.parse(localStorage.getItem('blogPostsDrafts'));
    const rascunhoFilter = rascunhosSalvos.filter((rs) => rs.title !== rascunho.title);
    if (rascunhoFilter.length === 0) {
      localStorage.removeItem('blogPostsDrafts');
      return window.location.reload();
    }
    localStorage.setItem('blogPostsDrafts', JSON.stringify(rascunhoFilter));
    return window.location.reload();
  }

  return (
    <div className="CriarPost">
      <div className="formHeader">
        <h1 className="headingText">Nova Publicação</h1>
      </div>
      <div className="formBody">
        <input type="text" placeholder="Inserir Título" name="title" className="standardInput" value={postTitle} onChange={(e) => handleChange(e.target)} />
        <textarea placeholder="Comece o seu texto aqui" name="body" className="standardInput" value={postBody} onChange={(e) => handleChange(e.target)} />
        <div className="addMediaDiv">
          <label className="fileInput" htmlFor="imageInput">
            Adicionar Mídia
            <input accept="image/png, image/jpeg, image/jpg, image/webp" name="imageInput" type="file" id="imageInput" />
          </label>
          <div className={`previewImagesDiv ${hiddenClass}`}>
            <p>pré visualização (clique na imagem para remover)</p>
            <img onClick={removeImage} alt="" src={image} />
          </div>
        </div>
        <div>
          <input type="text" placeholder="Autor:" name="author" className="inputWithBottomBorder" value={postAuthor} onChange={(e) => handleChange(e.target)} />
          {/* <input type="date" id="aa" placeholder="dd/mm/yyyy" name="date" className="inputWithBottomBorder" value={postDate} onChange={(e) => handleChange(e.target)} /> */}
          <input type="text" placeholder="Tempo de leitura: (Em minutos)" name="readTime" className="inputWithBottomBorder" value={postReadTime} onChange={(e) => handleChange(e.target)} />
        </div>
        <button onClick={handleClick} type="button" className="genericBtn standardText">
          Publicar
        </button>
        {
          rascunho === undefined
            ? <p onClick={handleSaveTextClick} className="standardText saveText">Salvar como Rascunho</p>
            : <p onClick={handleDeleteDraftText} className="standardText saveText">Apagar Rascunho</p>
        }
      </div>
    </div>
  );
}

export default CriarPostComponent;
