/* eslint-disable no-confusing-arrow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { getMonths, fetchDataFromLocalStorage } from '../../context/DatabaseConnection';
import history from '../../context/history';
import notification from '../../Media/Sidebar/notification.png';
import notificationActive from '../../Media/Header/notificationActive.svg';
import questionMark from '../../Media/Sidebar/questionMark.svg';
import './Header.css';
import NPSComponent from './NpsComponent';

function Header({ headerData }) {
  const date = new Date();
  const month = getMonths[date.getMonth()].substring(0, 3).toLowerCase();
  const [, pathname] = history.location.pathname.split('/');
  const [npsState, setNpsState] = React.useState(false);
  const [notificationIcon, setNotificationIcon] = React.useState(notification);

  const { user } = headerData;
  const username = user.fullName.split(' ')[0].toLowerCase();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  let headerText = `Olá, ${capitalizeFirstLetter(username)}!`;

  switch (pathname) {
    case 'blog':
      headerText = 'Carmel Blog';
      break;
    case 'atendimento':
      headerText = 'Atendimento';
      break;
    case 'pagamentos':
      headerText = 'Pagamentos';
      break;
    case 'galeria':
      headerText = 'Galeria';
      break;
    case 'editarConta':
      headerText = 'Editar Conta';
      break;
    case 'documentacao':
      headerText = 'Documentação';
      break;
    case 'clientes':
      headerText = 'Clientes Carmel';
      break;
    case 'help':
      headerText = 'Tutoriais Carmel App';
      break;
    case 'suporte':
      headerText = 'Suporte Kaizen';
      break;
    default:
      break;
  }

  if (user.admin === true) {
    return (
      <div className="homeHeader">
        <h2>{headerText}</h2>
        <div className="headerSideContent">
          <p>{`${date.getDate()} ${month} ${date.getFullYear()}`}</p>
          <img onClick={() => history.push('/help')} src={questionMark} alt="Notificações" />
        </div>
      </div>
    );
  }

  const { NPSpopupState, token } = fetchDataFromLocalStorage();

  if (NPSpopupState === null && notificationIcon !== notificationActive) {
    setNotificationIcon(notificationActive);
  }

  function handleClick() {
    if (notificationIcon !== notificationActive) {
      return;
    }
    if (npsState === true) {
      setNpsState(false);
    } else setNpsState(true);
  }

  return (
    <div className="homeHeader">
      <h2>{headerText}</h2>
      <div className="headerSideContent">
        <p>{`${date.getDate()} ${month} ${date.getFullYear()}`}</p>
        <img onClick={handleClick} src={notificationIcon} alt="Notificações" />
        <NPSComponent npsState={npsState} setNpsState={setNpsState} token={token} />
      </div>
    </div>
  );
}

export default Header;
