import './App.css';
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './context/history';
import LoginPage from './pages/LoginPage';
import GlobalContext from './context/GlobalContext';
import CriarSenha from './pages/CriarSenha';
import Home from './pages/Home';
import CriarPost from './pages/CriarBlogPost';
import BlogPost from './pages/BlogPost';
import BlogPage from './pages/BlogPage';
import AtendimentoPage from './pages/AtendimentoPage';
import PagamentosPage from './pages/PagamentosPage';
import GaleriaPage from './pages/Galeria';
import EditarContaPage from './pages/EditarContaPage';
import CriarUsuario from './pages/CriarUsuario';
import HelpPage from './pages/HelpPage';
import SuportePage from './pages/SuporteKaizen';

function App() {
  return (
    <GlobalContext>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/criarSenha" component={CriarSenha} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/blog/criar" component={CriarPost} />
          <Route exact path="/blog" component={BlogPage} />
          <Route exact path="/blog/:id" component={BlogPost} />
          <Route exact path="/atendimento" component={AtendimentoPage} />
          <Route exact path="/pagamentos" component={PagamentosPage} />
          <Route exact path="/galeria" component={GaleriaPage} />
          <Route exact path="/documentacao" component={GaleriaPage} />
          <Route exact path="/editarConta" component={EditarContaPage} />
          <Route exact path="/clientes" component={CriarUsuario} />
          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/suporte" component={SuportePage} />
        </Switch>
      </Router>
    </GlobalContext>
  );
}

export default App;
