/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import base64 from 'base-64';
import { fetchDataFromLocalStorage, getMonths } from '../../context/DatabaseConnection';
import goBackArrow from '../../Media/Pagamentos/goBackArrow.svg';
import search from '../../Media/Pagamentos/search.svg';

const getMonthNumeral = {
  janeiro: '01',
  fevereiro: '02',
  marco: '03',
  março: '03',
  abril: '04',
  maio: '05',
  junho: '06',
  julho: '07',
  agosto: '08',
  setembro: '09',
  outubro: '10',
  novembro: '11',
  dezembro: '12',
};

function ExtractComponent({ setSeeExtract }) {
  const { user } = fetchDataFromLocalStorage();
  const [debitBalance, setDebitBalance] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [paidBills, setPaidBills] = React.useState(false);
  const [extractFilter, setExtractFilter] = React.useState('');

  React.useEffect(() => {
    async function fetchContent() {
      const response = await fetch(`https://api.sienge.com.br/fteinc/public/api/v1/customers/${user.siengeId}`, {
        mode: 'cors',
        headers: {
          Authorization: `Basic ${base64.encode('fteinc-kaizen:Otg55JSYc1XvlRAMGUY4ChPs6HiqaVBL')}`,
        },
      });
      const res = await response.json();
      const { cpf, cnpj } = res;
      let variableToUse = `?cpf=${cpf}`;
      // eslint-disable-next-line no-unused-expressions
      cpf === undefined ? variableToUse = `?cnpj=${cnpj}` : variableToUse = `?cpf=${cpf}`;
      const debtBalance = await fetch(`https://api.sienge.com.br/fteinc/public/api/v1/current-debit-balance${variableToUse}`, {
        mode: 'cors',
        headers: {
          Authorization: `Basic ${base64.encode('fteinc-kaizen:Otg55JSYc1XvlRAMGUY4ChPs6HiqaVBL')}`,
        },
      });
      const returnValue = await debtBalance.json();
      return returnValue;
    }

    setTimeout(async () => {
      const response = await fetchContent();
      setDebitBalance(response.results);
    }, 500);
  }, []);

  function getAllBills() {
    const bills = {
      paidInstallments: [],
      dueInstallments: [],
      payableInstallments: [],
    };
    debitBalance.forEach((lote) => {
      if (lote.paidInstallments !== undefined) {
        bills.paidInstallments = [...bills.paidInstallments, ...lote.paidInstallments];
      }
      if (lote.dueInstallments !== undefined) {
        bills.dueInstallments = [...bills.dueInstallments, ...lote.dueInstallments];
      }
      if (lote.payableInstallments !== undefined) {
        bills.payableInstallments = [...bills.payableInstallments, ...lote.payableInstallments];
      }
    });
    return bills;
  }

  const allBills = getAllBills();
  const asdas = allBills.paidInstallments;
  asdas.reverse();
  if (asdas.length !== 0 && paidBills === false) {
    setPaidBills(asdas);
  }

  function handleChange(value) {
    setExtractFilter(value);
  }

  if (paidBills === false) {
    return (
      <div className="standardHomeDiv">
        <div className="extractHeader">
          <div onClick={() => { setSeeExtract(false); }}>
            <img alt="voltar" src={goBackArrow} />
            <p>voltar</p>
          </div>
        </div>
        <div className="extractBody">
          <h1 style={{ marginLeft: 'auto', marginRight: 'auto' }} className="headingText">Resumo Consolidado do Extrato</h1>

          <div className="tableHeader">
            <div className="extractInputDiv">
              <img src={search} alt="" />
              <input placeholder="datas" value={extractFilter} onChange={(e) => handleChange(e.target.value)} />
            </div>
            <div className="tableSide">
              <div>
                <p>valor</p>
                <p className="orangeText">$</p>
              </div>
              <div>
                <p>reajuste</p>
                <p className="orangeText">%</p>
              </div>
            </div>
          </div>

          <div className="tableMain">
            <h1 style={{ margin: '0', textAlign: 'center' }} className="headingText">Carregando</h1>
          </div>

        </div>
      </div>
    );
  }

  console.log(debitBalance);

  return (
    <div className="standardHomeDiv">
      <div className="extractHeader">
        <div onClick={() => { setSeeExtract(false); }}>
          <img alt="voltar" src={goBackArrow} />
          <p>voltar</p>
        </div>
      </div>
      <div className="extractBody">
        <h1 style={{ marginLeft: 'auto', marginRight: 'auto' }} className="headingText">Resumo Consolidado do Extrato</h1>

        <div className="tableHeader">
          <div className="extractInputDiv">
            <img src={search} alt="" />
            <input placeholder="datas" value={extractFilter} onChange={(e) => handleChange(e.target.value)} />
          </div>
          <div className="tableSide">
            <div>
              <p>valor</p>
              <p className="orangeText">$</p>
            </div>
            <div>
              <p>reajuste</p>
              <p className="orangeText">%</p>
            </div>
          </div>
        </div>

        <div className="tableMain">
          {paidBills.map((installment, id) => {
            const corretionValue = Math.round((installment.originalValue / installment.adjustedValue) * 100) / 100;
            const [installmentYear, installmentMonth, installmentDay] = installment.dueDate.split('-');
            const month = (getMonths[installmentMonth - 1]).toLowerCase().substring(0, 3);
            if (extractFilter !== '') {
              if (getMonthNumeral[extractFilter.toLowerCase()] === installmentMonth) {
                return (
                  <div key={id}>
                    <div className="tableBody">
                      <div className="installmentMonth">
                        <p>{installmentDay} <strong className="hardText">{month}</strong> {installmentYear}</p>
                      </div>
                      <div className="tableSide">
                        <div>
                          <p>{installment.adjustedValue}</p>
                        </div>
                        <div>
                          <p>{corretionValue}%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              const [filterDay, filterMonth, filterYear] = extractFilter.split('/');
              if (filterDay === installmentDay && filterMonth === installmentMonth && filterYear === installmentYear) {
                return (
                  <div key={id}>
                    <div className="tableBody">
                      <div className="installmentMonth">
                        <p>{installmentDay} <strong className="hardText">{month}</strong> {installmentYear}</p>
                      </div>
                      <div className="tableSide">
                        <div>
                          <p>{installment.adjustedValue}</p>
                        </div>
                        <div>
                          <p>{corretionValue}%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return '';
            }
            return (
              <div key={id}>
                <div className="tableBody">
                  <div className="installmentMonth">
                    <p>{installmentDay} <strong className="hardText">{month}</strong> {installmentYear}</p>
                  </div>
                  <div className="tableSide">
                    <div>
                      <p>{installment.adjustedValue}</p>
                    </div>
                    <div>
                      <p>{corretionValue}%</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

      </div>
    </div>
  );
}

export default ExtractComponent;
