/* eslint-disable no-unused-vars */
import React from 'react';
import HomeComponent from '../components/Home/HomeComponent';
import databaseUrl, { fetchDataFromLocalStorage } from '../context/DatabaseConnection';

function Home() {
  const { user, token } = fetchDataFromLocalStorage();
  const [clients, setClients] = React.useState(false);

  React.useEffect(() => {
    async function fetchClients() {
      const response = await fetch(`${databaseUrl}/users`, {
        method: 'GET',
        headers: {
          authorization: token,
          'Content-Type': 'application/json',
        },
      });
      const res = await response.json();
      setClients(res.length);
    }

    fetchClients();
  }, []);

  return (
    <div>
      <HomeComponent user={user} clients={clients} />
    </div>
  );
}

export default Home;
